// @mui material components
import Card from "@mui/material/Card";
import { Checkbox, Grid, Table, TableBody, TableContainer } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useEffect, useMemo, useState } from "react";
import SoftInput from "components/SoftInput";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExternalLinkAlt,
  faPlus,
  faSpinner,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { MaterialApi } from "services/Material";
import { GroupApi } from "services/Group";
import { StockModal } from "./stock-modal";
import { useSearchParams } from "react-router-dom";
import { CreateModal } from "./create-modal";
import { EditModal } from "./edit-modal";
import { IssueModal } from "./issue-modal";
import { ProjectApi } from "services/Project";
import { IssueList } from "./components/issue-list";
import { useEvents } from "hooks/useEvents";
import { AuthService } from "services/Auth";

function Materials() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const [issuing, setIssuing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [groups, setGroups] = useState([]);
  const [projects, setProjects] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState({});
  const [deleting, setDeleting] = useState(null);
  const [stocking, setStocking] = useState(false);
  const [issues, setIssues] = useState([]);
  const [loadingIssues, setLoadingIssues] = useState(false);

  const { subscribe, unsubscribe } = useEvents();

  const deleteMaterial = async (material) => {
    if (!confirm(`Deseja realmente excluir o material "${material.name}"?`)) {
      return;
    }

    setDeleting(material.id);
    await MaterialApi.destroy(material.id);
    setDeleting(null);
    fetchMaterials();
    fetchIssues();
  };

  const fetchMaterials = async () => {
    const response = await MaterialApi.list();

    if (!response.ok) {
      console.error(response);
      return;
    }

    setMaterials(response.materials);
  };

  const fetchGroups = async () => {
    const response = await GroupApi.list();

    if (!response.ok) {
      console.error(response);
      return;
    }

    setGroups(response.groups);
  };

  const fetchProjects = async () => {
    const response = await ProjectApi.list();

    if (!response.ok) {
      error(response.message);
      return;
    }

    setProjects(response.projects);
  };

  const fetchIssues = async () => {
    setLoadingIssues(true);
    const response = await MaterialApi.issues();
    setLoadingIssues(false);

    if (!response.ok) {
      return;
    }

    setIssues(response.issues);
  };

  useEffect(() => {
    fetchMaterials();
    fetchGroups();
    fetchProjects();
    fetchIssues();

    subscribe("filters-updated", "materials", () => {
      fetchMaterials();
      fetchIssues();
    });

    return () => {
      unsubscribe("filters-updated", "materials");
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "materiais",
            uri: "/materiais",
          },
        ]}
      />

      <StockModal
        showing={stocking}
        material={currentMaterial}
        projects={projects}
        onSuccess={() => {
          fetchIssues();
          fetchMaterials();
          setCurrentMaterial(null);
          setStocking(false);
          setSelected([]);
        }}
        onClose={() => {
          setStocking(false);
          setCurrentMaterial(null);
        }}
      />

      <CreateModal
        showing={creating}
        onClose={() => {
          setCreating(false);
        }}
        onSuccess={() => {
          fetchMaterials();
          fetchIssues();
          setCreating(false);
        }}
        groups={groups}
      />

      <EditModal
        showing={editing}
        onClose={() => {
          setEditing(false);
        }}
        onSuccess={() => {
          fetchMaterials();
          setEditing(false);
        }}
        groups={groups}
        material={currentMaterial}
      />

      <IssueModal
        showing={issuing}
        materials={selected}
        projects={projects}
        onClose={() => {
          setIssuing(false);
        }}
        onSuccess={() => {
          setSelected([]);
          fetchMaterials();
          fetchIssues();
        }}
        onRemove={(material) => {
          setSelected(selected.filter((m) => m.id != material.id));
        }}
      />

      <SoftBox py={3}>
        <Grid container gap={1}></Grid>
      </SoftBox>

      <Grid container>
        {/* Lista de materiais */}
        <Grid item lg={8} md={12} sm={12}>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item lg={3} md={4} sm={6}>
              <SoftButton
                color="success"
                onClick={() => setCreating(true)}
                style={{ width: "100%" }}
                disabled={AuthService.isBlocked()}
              >
                + Cadastrar material
              </SoftButton>
            </Grid>

            <Grid item lg={3} md={4} sm={6}>
              <SoftButton
                color="info"
                disabled={selected.length < 1 || AuthService.isBlocked()}
                style={{ width: "100%", marginLeft: 10 }}
                onClick={() => setIssuing(true)}
              >
                Saída ({selected.length} selecionados){" "}
                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: 15 }} />
              </SoftButton>
            </Grid>
          </Grid>
          <SoftInput
            placeholder="Buscar..."
            icon={{ component: "search", direction: "left" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Card style={{ marginTop: 10 }}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Materiais</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <TableContainer>
                <Table>
                  <SoftBox component="thead">
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"center"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      #
                    </SoftBox>
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"left"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      Nome
                    </SoftBox>
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"left"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      Unidade
                    </SoftBox>
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"left"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      Preco
                    </SoftBox>
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"left"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      Em estoque
                    </SoftBox>
                    <SoftBox
                      component="th"
                      width={"auto"}
                      pt={1.5}
                      pb={1.25}
                      pl={3}
                      textAlign={"center"}
                      fontSize={typography.size.xxs}
                      fontWeight={"bold"}
                      color="secondary"
                      opacity={0.7}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                    >
                      Acao
                    </SoftBox>
                  </SoftBox>

                  <TableBody>
                    {materials
                      .filter((m) => m.name.includes(searchTerm))
                      .map((material) => (
                        <SoftBox component="tr" key={material.id}>
                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"center"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftTypography
                              variant="button"
                              fontWeight="regular"
                              color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              <Checkbox
                                name={`checkbox-material-${material.id}`}
                                id={`checkbox-material-${material.id}`}
                                onChange={(e) => {
                                  setSelected((prev) => {
                                    if (e.target.checked) {
                                      return [...prev, material];
                                    }

                                    return [...prev].filter((p) => p.id !== material.id);
                                  });
                                }}
                                value={material.id}
                              />
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"left"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftTypography
                              component="label"
                              htmlFor={`checkbox-material-${material.id}`}
                              fontWeight="regular"
                              fontSize={typography.size.sm}
                              color="secondary"
                              sx={{
                                display: "inline-block",
                                width: "max-content",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {material.name}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"left"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftTypography
                              fontWeight="regular"
                              fontSize={typography.size.sm}
                              color="secondary"
                              sx={{
                                display: "inline-block",
                                width: "max-content",
                              }}
                            >
                              {material.unit}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"left"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftTypography
                              fontWeight="regular"
                              fontSize={typography.size.sm}
                              color="secondary"
                              sx={{
                                display: "inline-block",
                                width: "max-content",
                              }}
                            >
                              {material.price.asMoney()}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"left"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftTypography
                              fontWeight="regular"
                              fontSize={typography.size.sm}
                              color={material.stock > 0 ? "secondary" : "error"}
                              sx={{
                                display: "inline-block",
                                width: "max-content",
                              }}
                            >
                              {material.stock}
                            </SoftTypography>
                          </SoftBox>

                          <SoftBox
                            component="td"
                            p={1}
                            textAlign={"right"}
                            borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                          >
                            <SoftButton
                              color="success"
                              onClick={() => {
                                setCurrentMaterial(material);
                                setStocking(true);
                              }}
                              disabled={AuthService.isBlocked()}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </SoftButton>
                            <SoftButton
                              color="white"
                              onClick={() => {
                                setEditing(true);
                                setCurrentMaterial(material);
                              }}
                              disabled={AuthService.isBlocked()}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </SoftButton>
                            <SoftButton
                              color="error"
                              onClick={() => deleteMaterial(material)}
                              disabled={!!deleting || AuthService.isBlocked()}
                            >
                              <FontAwesomeIcon
                                icon={deleting && deleting == material.id ? faSpinner : faTrashCan}
                                spin={deleting && deleting == material.id}
                              />
                            </SoftButton>
                          </SoftBox>
                        </SoftBox>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </SoftBox>
          </Card>
        </Grid>
        <Grid item lg={4} sm={12} md={12}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pt={0}>
              <SoftTypography variant="h6">
                {loadingIssues && (
                  <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: 10 }} />
                )}
                Movimentacoes ({issues.length})
              </SoftTypography>
            </SoftBox>

            <IssueList issues={issues} style={{ marginTop: 10 }} />
          </SoftBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Materials;
