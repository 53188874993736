/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, redirect, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/pertrolina-catedral.webp";
import { AuthApi } from "services/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function SignIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const username = e.target["techgest--input-username"].value;
    const password = e.target["techgest--input-password"].value;

    const response = await AuthApi.login(username, password);
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    // TODO: arrumar isso. Só está assim por conta do AuthService.isBlocked()
    window.location.href = "/inicio";
  };

  return (
    <CoverLayout
      title="Olá! Bom te ver novamente."
      description="Insira seu e-mail e senha para entrar"
      image={curved9}
      action={{
        type: "internal",
        route: "/cadastro",
        label: "Criar conta",
        color: "dark",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nome de usuário:
            </SoftTypography>
          </SoftBox>
          <SoftInput type="text" placeholder="" name="techgest--input-username" required />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Senha
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="********"
            name="techgest--input-password"
            required
          />
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
            Entrar
            {loading && <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />}
          </SoftButton>
        </SoftBox>

        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Não possui conta ainda?&nbsp;
            <SoftTypography
              component={Link}
              to="/cadastro"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Criar conta agora
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
