/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import { AuthService } from "services/Auth";
import { useEffect, useState } from "react";
import { ProjectApi } from "services/Project";
import { ProfileEditForm } from "./profile-edit-form";
import { PasswordUpdateForm } from "./password-update-form";

function Overview() {
  const ME = AuthService.user();

  const [projects, setProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [editing, setEditing] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const fetchProjects = async () => {
    setLoadingProjects(true);
    const response = await ProjectApi.list();
    setLoadingProjects(false);

    if (!response.ok) {
      error(response.messag);
      return;
    }

    setProjects(response.projects);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* 
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid> */}
          <Grid item xs={12} md={6} xl={4}>
            {!editing && !updatingPassword && (
              <ProfileInfoCard
                title="perfil"
                description=""
                info={{
                  nome: ME.name,
                  email: ME.email,
                  usuario: ME.username,
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ].filter((s) => !!s.visible)}
                onEdit={() => {
                  setEditing(true);
                }}
                onChangePassword={() => {
                  setUpdatingPassword(true);
                }}
              />
            )}

            {editing && (
              <Card>
                <SoftBox p={2}>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Editar cadastro</SoftTypography>
                  </SoftBox>
                  <ProfileEditForm
                    onCancel={() => setEditing(false)}
                    onSuccess={() => setEditing(false)}
                  />
                </SoftBox>
              </Card>
            )}

            {updatingPassword && (
              <Card>
                <SoftBox p={2}>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Alterar senha</SoftTypography>
                  </SoftBox>
                  <PasswordUpdateForm
                    onCancel={() => setUpdatingPassword(false)}
                    onSuccess={() => setUpdatingPassword(false)}
                  />
                </SoftBox>
              </Card>
            )}
          </Grid>
          <Grid item lg={8} xl={8} sm={12} xs={12}>
            <SoftBox pt={2} px={2}>
              <SoftBox mb={0.5}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Obras
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Projetos já trabalhados no sistema
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox p={2}>
              <Grid container spacing={3}>
                {projects.map((project) => (
                  <Grid item xs={12} md={6} xl={3} key={`profile-project-${project.id}`}>
                    <DefaultProjectCard
                      image={homeDecor1}
                      label={project.group.name}
                      title={project.name}
                      description={`${project.addressStreet}, ${project.addressNumber ?? "SN"}. ${project.addressCity} - ${project.addressState}`}
                      action={{
                        type: "internal",
                        route: `/obras/${project.slug}`,
                        color: "info",
                        label: "Ver obra",
                      }}
                      authors={[]}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} md={6} xl={3}>
                  <PlaceholderCard title={{ variant: "h5", text: "Nova Obra" }} outlined />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
          {/*
          <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid>*/}
        </Grid>
      </SoftBox>
      <SoftBox mb={3}>
        <Card></Card>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
