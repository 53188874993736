import { BaseApi } from "./BaseApi";
import { HTTP_ACCEPTED } from "config/constants/http";

export const ProfileApi = {
  update: async ({ name, email, username }) => {
    const response = await BaseApi.make("/api/profile/update", "PUT", {
      name,
      email,
      username,
    });

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  updatePassword: async (currentPassword, password, password_confirmation) => {
    const response = await BaseApi.make("/api/profile/password", "PUT", {
      currentPassword,
      password,
      password_confirmation,
    });

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
};
