import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import { AuthService } from "services/Auth";
import { ProfileApi } from "services/Profile";

export const PasswordUpdateForm = ({ onCancel, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const currentPassword = e.target["techgest--input-password"].value;
    const password = e.target["techgest--input-userPassword"].value;
    const passwordConfirmation = e.target["techgest--input-userPasswordConfirmation"].value;

    if (password !== passwordConfirmation) {
      error("As senhas digitadas são diferentes");
      return;
    }

    setLoading(true);
    const response = await ProfileApi.updatePassword(
      currentPassword,
      password,
      passwordConfirmation
    );
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    AuthService.user(response.user);
    success("Senha atualizada");
    onSuccess();
  };

  return (
    <SoftBox component="form" onSubmit={onSubmit}>
      <Grid container mb={2} gap={1}>
        <Grid item lg={12} md={6} sm={12}>
          <SoftBox mb={1}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Senha atual:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            name="techgest--input-password"
            id="techgest--input-password"
            minlength="8"
            placeholder="********"
            autofocus
            required
          />
        </Grid>

        <Grid item lg={12} md={6} sm={12}>
          <SoftBox mb={1}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nova senha:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            name="techgest--input-userPassword"
            id="techgest--input-userPassword"
            minlength="8"
            placeholder="********"
            required
          />
        </Grid>

        <Grid item lg={12} md={6} sm={12}>
          <SoftBox mb={1}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Confirmacao de senha:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            name="techgest--input-userPasswordConfirmation"
            id="techgest--input-userPasswordConfirmation"
            minlength="8"
            placeholder="********"
            required
          />
        </Grid>
      </Grid>

      <Divider />

      <SoftBox display="flex">
        <SoftBox mr={2}>
          <SoftButton type="button" color="white" onClick={onCancel} mr={2}>
            Cancelar
          </SoftButton>
        </SoftBox>

        <SoftBox mr={2}>
          <SoftButton type="submit" color="success" disabled={loading}>
            Salvar nova senha
            {loading && <FontAwesomeIcon icon={faSpinner} spin />}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};
