import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/pertrolina-catedral.webp";
import { AuthApi } from "services/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target["techgest--input-name"].value;
    const username = e.target["techgest--input-username"].value;
    const email = e.target["techgest--input-email"].value;
    const password = e.target["techgest--input-password"].value;
    const passwordConfirmation = e.target["techgest--input-passwordConfirmation"].value;

    if (password !== passwordConfirmation) {
      error("As senhas não conferem!");
      return;
    }

    setLoading(true);
    const response = await AuthApi.register({
      name,
      username,
      email,
      password,
      passwordConfirmation,
    });
    setLoading(true);

    if (!response.ok) {
      error(response.message);
      return;
    }

    // TODO: arrumar isso. Só está assim por conta do AuthService.isBlocked()
    window.location.href = "/inicio";
  };

  return (
    <CoverLayout
      title="Primeiro acesso"
      description="Entre em contato"
      image={curved9}
      action={{
        type: "internal",
        route: "/entrar",
        label: "Entrar",
        color: "dark",
      }}
    >
      <SoftTypography variant="p" fontSize="14">
        Você pode entrar em contato através do número 8799988-8708 ou e-email email@teste.com.
        Escreva falando do seu interesse no sistema e deixa o resto conosco ;)
      </SoftTypography>
    </CoverLayout>
  );
}

export default SignUp;
