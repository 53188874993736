import { Card, Table, TableBody, TableContainer } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { useMemo, useState } from "react";
import SoftInput from "components/SoftInput";

export const SimpleTable = ({
  title,
  header,
  onFilter,
  resolvers,
  keyResolver,
  items,
  searchPlaceholder = "Busque na tabela...",
  ...rest
}) => {
  const [term, setTerm] = useState("");

  const filteredItems = useMemo(() => {
    if (!onFilter) {
      return items;
    }

    return items.filter((item) => onFilter(item, term));
  }, [term, items, onFilter]);

  return (
    <Card {...rest}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftTypography variant="h6">{title}</SoftTypography>
      </SoftBox>

      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftInput placeholder={searchPlaceholder} onChange={(e) => setTerm(e.target.value)} />
      </SoftBox>

      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <TableContainer>
          <Table>
            <SoftBox component="thead">
              {header.map((name) => (
                <SoftBox
                  component="th"
                  width={"auto"}
                  pt={1.5}
                  pb={1.25}
                  pl={3}
                  textAlign={"left"}
                  fontSize={typography.size.xxs}
                  fontWeight={"bold"}
                  color="secondary"
                  opacity={0.7}
                  borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  key={`header-${name}`}
                >
                  {name}
                </SoftBox>
              ))}
            </SoftBox>
            <TableBody>
              {filteredItems.map((item, index) => (
                <SoftBox component="tr" key={`item-${item.id}`}>
                  {header.map((_, headerIndex) => (
                    <SoftBox
                      component="td"
                      p={1}
                      textAlign={item.align ?? "left"}
                      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      key={keyResolver(item, headerIndex)}
                    >
                      {resolvers[headerIndex](item, index)}
                    </SoftBox>
                  ))}
                </SoftBox>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SoftBox>
    </Card>
  );
};
