import { faBox, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import colors from "assets/theme/base/colors";
import SoftBox from "components/SoftBox";
import TimelineItem from "examples/Timeline/TimelineItem";

export const IssueList = ({ issues, project }) => {
  const renderIssue = (issue) => {
    const date = new Date(issue.created_at).toLocaleString("pt-BR");
    let projectText = "";

    if (issue.project || project) {
      const name = issue.project ? issue.project.name : project.name;
      projectText = ` - Obra: ${name}`;
    }

    const directionText = issue.direction == "in" ? "Entrada" : "Saída";
    const issueValueText = issue.cost.asMoney() + " - ";

    if (!issue.material) {
      issue.material = {
        name: "[Material excluído]",
      };
    }

    return (
      <TimelineItem
        color={issue.direction === "in" ? colors.error.main : colors.info.main}
        icon={issue.direction === "in" ? faBox : faBoxOpen}
        title={`${issueValueText}${issue.material.name} - ${issue.amount}${issue.unit}`}
        dateTime={`${date}${projectText} / ${directionText} por: ${issue.user.name}`}
      />
    );
  };
  return (
    <SoftBox>
      <SoftBox p={2}>{issues.map(renderIssue)}</SoftBox>
    </SoftBox>
  );
};
