import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useEvents } from "hooks/useEvents";
import { Transaction } from "layouts/financies/transaction";
import { useEffect, useState } from "react";
import { TransactionApi } from "services/Transaction";

const LIMIT = 10;

export const TransactionsContainer = ({ projectId }) => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const { subscribe, unsubscribe } = useEvents();

  const fetchTransactions = async () => {
    setLoading(true);
    const response = await TransactionApi.list(LIMIT, page, {
      projectId,
    });
    setLoading(false);

    if (!response.ok) {
      error("Falha ao obter a lista de movimentacoes");
      return;
    }

    setData(response.data);

    if (page == 1) {
      setTransactions(response.data.data);
      return;
    }

    setTransactions((old) => [...old, ...response.data.data]);
  };

  useEffect(() => {
    fetchTransactions();

    subscribe("transaction-stored", "TransactionsContainer", () => {
      fetchTransactions();
    });

    return () => {
      unsubscribe("transaction-stored", "TransactionsContainer");
    };
  }, [projectId, page]);

  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Transacoes
          {loading && <FontAwesomeIcon spin icon={faSpinner} style={{ marginLeft: 10 }} />}
          {!loading && data && ` (${data.total})`}
        </SoftTypography>
        <SoftBox p={2}>
          {transactions.map((transaction) => (
            <Transaction transaction={transaction} key={`transaction-${transaction.id}`} />
          ))}
        </SoftBox>
      </SoftBox>

      <SoftButton
        color="info"
        disabled={(data && data.total <= transactions.length) || loading}
        onClick={() => {
          setPage(page + 1);
        }}
      >
        <FontAwesomeIcon
          icon={loading ? faSpinner : faPlus}
          spin={loading}
          style={{ marginRight: 10 }}
        />
        Carregar mais{" "}
      </SoftButton>
    </Card>
  );
};
