import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";

import { Logout } from "layouts/logout";
import Materials from "layouts/materials";
import Financies from "layouts/financies";
import Groups from "layouts/groups";
import Projects from "layouts/projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxes,
  faBuilding,
  faHome,
  faMoneyBill,
  faSignOut,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "services/Auth";

const routes = [
  {
    type: "collapse",
    name: "Início",
    key: "inicio",
    route: "/inicio",
    icon: <FontAwesomeIcon icon={faHome} size="xs" />,
    component: <Dashboard />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Empresas",
    key: "empresas",
    route: "/empresas",
    icon: <FontAwesomeIcon icon={faUsers} size="xs" />,
    component: <Groups />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Obras",
    key: "obras",
    route: "/obras",
    icon: <FontAwesomeIcon icon={faBuilding} size="xs" />,
    component: <Projects />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Materiais",
    key: "materiais",
    route: "/materiais",
    icon: <FontAwesomeIcon icon={faBoxes} size="xs" />,
    component: <Materials />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Financeiro",
    key: "financeiro",
    route: "/financeiro",
    icon: <FontAwesomeIcon icon={faMoneyBill} size="xs" />,
    component: <Financies />,
    noCollapse: true,
    protection: "auth",
  },
  { type: "title", title: "Conta", key: "account-pages" },
  {
    type: "collapse",
    get name() {
      return AuthService.user().name;
    },
    key: "profile",
    route: "/profile",
    icon: <FontAwesomeIcon icon={faUser} size="xs" />,
    component: <Profile />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Sair",
    key: "logout",
    route: "/logout",
    icon: <FontAwesomeIcon icon={faSignOut} size="xs" />,
    component: <Logout />,
    noCollapse: true,
    protection: "auth",
  },
];

export default routes;
