import { faArrowRight, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEvents } from "hooks/useEvents";
import { useEffect, useState } from "react";
import { AuthService } from "services/Auth";
import { ProjectApi } from "services/Project";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export default function() {
  const [loading, setLoading] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [projects, setProjects] = useState([]);
  const [steps, setSteps] = useState([]);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [stepId, setStepId] = useState(0);

  const { dispatch } = useEvents();

  const loadProjects = async () => {
    setLoadingProjects(true);
    const response = await ProjectApi.list();
    setLoadingProjects(false);

    if (!response.ok) {
      return;
    }

    setProjects(response.projects);

    if (response.projects.length > 0) {
      setProjectId(response.projects[0].id);
    }
  };

  const loadSteps = async () => {
    setLoadingSteps(true);
    const response = await ProjectApi.listStepsFromProject(projectId);
    setLoadingSteps(false);

    if (!response.ok) {
      return;
    }

    setSteps(response.steps);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await ProjectApi.updateStepProgress(stepId, progress);
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    dispatch("progress-registered", {
      stepId,
      progress,
    });

    success("Progresso da etapa atualizado");
    await loadSteps();
    setShow(false);
  };

  useEffect(() => {
    loadProjects();
  }, []);

  useEffect(() => {
    loadSteps();
  }, [projectId]);

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="5rem"
        height="5rem"
        bgColor="green"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="white"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          if (AuthService.isBlocked()) {
            error("Usuário bloqueado. Acão não permitida.");
            return;
          }

          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </SoftBox>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">Atualizar progresso:</p>

          <Divider />

          <SoftBox component="form" role="form" onSubmit={handleSubmit}>
            {!loadingProjects && (
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Obra:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    name="techgest--input-project"
                    id="techgest--input-project"
                    required
                    value={projectId}
                    onChange={(e) => {
                      setProjectId(Number(e.target.value));
                    }}
                  >
                    <MenuItem value="invalid">Selecione uma obra</MenuItem>
                    {projects.map((project) => (
                      <MenuItem key={`project-${project.id}`} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </SoftBox>
            )}

            {
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Etapa:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    name="techgest--input-step"
                    id="techgest--input-step"
                    required
                    defaultValue="0"
                    onChange={(e) => {
                      if (e.target.value == "invalid") {
                        setProgress(0);
                        return;
                      }

                      const step = steps.find((step) => step.id == e.target.value);

                      setProgress(step.progress);
                      setStepId(step.id);
                    }}
                  >
                    <MenuItem value="0">Selecione uma etapa</MenuItem>
                    {steps.map((step) => (
                      <MenuItem key={`step-${step.id}`} value={step.id}>
                        {step.prefix} {step.name} ({step.progress}%)
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </SoftBox>
            }

            {
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Progresso (%):
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="text"
                    name="techgest--input-progress"
                    id="techgest--input-progress"
                    step="1"
                    placeholder="0"
                    value={progress}
                    onChange={(e) => {
                      let value = Number(e.target.value);

                      if (Number.isNaN(value)) return;

                      if (value < 0) value = 0;
                      if (value > 100) value = 100;
                      setProgress(value);
                    }}
                    disabled={!stepId || loadingSteps}
                  />
                </SoftBox>
              </SoftBox>
            }

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setShow(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              {!loadingProjects && (
                <SoftBox mr={2}>
                  <SoftButton type="submit" color="success" disabled={loading}>
                    Salvar{" "}
                    <FontAwesomeIcon
                      style={{ marginLeft: 10 }}
                      icon={loading ? faSpinner : faSave}
                      spin={loading}
                    />
                  </SoftButton>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
}
