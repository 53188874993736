// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";
import { Card, Divider } from "@mui/material";
import colors from "assets/theme/base/colors";
import "./styles/eap-styles.css";

import "react-contexify/ReactContexify.css";
import { useParams, useSearchParams } from "react-router-dom";
import { ProjectApi } from "services/Project";
import EapView from "./components/eap-view";
import EapEdit from "./components/eap-edit";
import { useEvents } from "hooks/useEvents";
import { EapTable } from "./components/eap-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faEdit, faFolderTree, faTable } from "@fortawesome/free-solid-svg-icons";
import SoftProgress from "components/SoftProgress";
import { SimpleForecastModal } from "./components/eap-edit/simple-forecast-modal";
import { ForecastListModal } from "./components/eap-edit/forecast-list-modal";
import { CurveS } from "./curve-s";

function ProjectEap() {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, setProject] = useState(null);
  const [data, setData] = useState(null);
  const [steps, setSteps] = useState([]);
  const [loadingProject, setLoadingProject] = useState(true);
  const [forecasting, setForecasting] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [addingForecast, setAddingForecast] = useState(false);

  const currentView = searchParams.get("v") ?? "table";
  const { subscribe, unsubscribe } = useEvents();

  const handleProgress = async (step) => {
    const r = Number(prompt(`Informe a nova porcentagem de progresso da etapa:`, step.progress));

    if (r == step.progress) {
      return;
    }

    const response = await ProjectApi.updateStepProgress(step.id, r);

    if (!response.ok) {
      error(response.message);
      return;
    }

    fetchProject();
  };

  const removeStep = async ({ id }) => {
    if (!confirm("Deseja realmente excluir esta etapa?")) {
      return;
    }

    const response = await ProjectApi.deleteStep(id);

    if (!response.ok) {
      error(response.message);
      return;
    }

    fetchProject();
  };

  const fetchProject = async () => {
    const response = await ProjectApi.getBySlug(slug);
    setLoadingProject(false);

    if (!response.ok) {
      return;
    }

    setData(response);
    setProject(response.project);
    setSteps(response.steps);
  };

  const turnIntoView = (view) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.append("v", view);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    subscribe("progress-registred", "projectEap", () => {
      fetchProject();
    });

    fetchProject();

    return () => {
      unsubscribe("progress-registred", "projectEap");
    };
  }, [searchParams]);

  if (!loadingProject && !project) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Obra não encontrada</h1>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Obras",
            uri: "/obras",
          },
          {
            name: project ? project.name : "Carregando..",
            uri: `/obras/${slug}`,
          },
          {
            name: "EAP - Gerenciamento",
            uri: `/obras/${slug}/eap`,
          },
        ]}
      />
      <SoftTypography>
        <strong>EAP - Gerenciamento</strong>
      </SoftTypography>

      <ForecastListModal
        step={currentStep}
        showing={forecasting}
        onClose={() => {
          setCurrentStep(null);
          setForecasting(false);
        }}
        onAddClicked={() => {
          setAddingForecast(true);
        }}
        onDelete={() => {
          fetchProject();
        }}
      />

      <SimpleForecastModal
        step={currentStep}
        showing={addingForecast}
        onClose={() => {
          setCurrentStep(null);
          setAddingForecast(false);
        }}
        onSuccess={({ keepEditing }) => {
          fetchProject();

          if (!keepEditing) {
            setAddingForecast(false);
          }
        }}
      />

      <Card style={{ position: "static" }}>
        <SoftBox
          px={3}
          py={3}
          borderBottom={1}
          borderColor={colors.grey["200"]}
          bgColor={"white"}
          variant={"contained"}
        >
          <SoftButton
            color="info"
            variant={currentView == "curve" ? "contained" : "outlined"}
            onClick={() => {
              turnIntoView("curve");
            }}
          >
            <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 10 }} />
            Curva
          </SoftButton>
          <SoftButton
            style={{ marginLeft: 20 }}
            color="info"
            variant={currentView == "table" ? "contained" : "outlined"}
            onClick={() => {
              turnIntoView("table");
            }}
          >
            <FontAwesomeIcon icon={faTable} style={{ marginRight: 10 }} />
            Tabela
          </SoftButton>
          <SoftButton
            style={{ marginLeft: 20 }}
            color="info"
            variant={currentView == "graph" ? "contained" : "outlined"}
            onClick={() => {
              turnIntoView("graph");
            }}
          >
            <FontAwesomeIcon icon={faFolderTree} style={{ marginRight: 10 }} />
            Árvore
          </SoftButton>
          <SoftButton
            style={{ marginLeft: 20 }}
            color="info"
            variant={currentView == "edit" ? "contained" : "outlined"}
            onClick={() => {
              turnIntoView("edit");
            }}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />
            Edicao
          </SoftButton>
        </SoftBox>

        <SoftBox px={2} pt={2}>
          <SoftTypography variant="caption">
            Custo estimado na EAP:
            <SoftTypography fontWeight="bold">
              {project ? project.estimatedEapCost.asMoney() : 0}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox px={2} mt={2}>
          <SoftTypography variant="caption">
            Progresso geral ({project ? project.progress : 0}%):
          </SoftTypography>
          <SoftProgress
            value={project ? project.progress : 0}
            variant="gradient"
            color={project && project.progress == 100 ? "success" : "info"}
            style={{ marginTop: 10 }}
          />
        </SoftBox>

        <Divider />

        {project && !loadingProject && currentView === "graph" && (
          <EapView project={project} steps={[...steps]} onEdit={fetchProject} />
        )}

        {project && !loadingProject && currentView === "edit" && (
          <EapEdit project={project} steps={[...steps]} onEdit={fetchProject} />
        )}

        {data && !loadingProject && currentView === "curve" && (
          <SoftBox p={5}>
            <CurveS
              data={data}
              curveA={{
                label: "Estimativa (%)",
                predicate: (estimation) => estimation.finalProgress,
              }}
              curveB={{
                label: "Realidade (%)",
                predicate: (estimation) => estimation.realProgress,
              }}
            />
          </SoftBox>
        )}

        {project && !loadingProject && currentView === "table" && (
          <SoftBox>
            <EapTable
              project={project}
              steps={[...steps]}
              onProgressUpdateClick={handleProgress}
              onDeleteClick={removeStep}
              onEstimatedCostClick={(step) => {
                setCurrentStep(step);
                setForecasting(true);
              }}
              onForecastCreateClick={(step) => {
                setCurrentStep(step);
                setAddingForecast(true);
              }}
            />
          </SoftBox>
        )}
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default ProjectEap;
