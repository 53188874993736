import SoftBox from "components/SoftBox";

import colors from "assets/theme/base/colors";

import "react-contexify/ReactContexify.css";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCalendarAlt,
  faPercentage,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import React, { useRef, useState } from "react";
import "./style.css";
import { ProjectApi } from "services/Project";
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";
import { ForecastListModal } from "./forecast-list-modal";
import { SimpleForecastModal } from "./simple-forecast-modal";
import { DatesModal } from "./dates-modal";
import { AuthService } from "services/Auth";

function EapEdit({ project, steps, onEdit = async () => { } }) {
  const [loadingCreateStep, setLoadingCreateStep] = useState(false);
  const [loadingDeleteStep, setLoadingDeleteStep] = useState(null);
  const [loadingAddStepInside, setLoadingAddStepInside] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(null);
  const [forecasting, setForecasting] = useState(false);
  const [changingDates, setChangingDates] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [addingForecast, setAddingForecast] = useState(false);
  const inputsRef = useRef({});

  const handleProgress = async (step) => {
    const r = Number(prompt(`Informe a nova porcentagem de progresso da etapa:`, step.progress));

    if (r == step.progress) {
      return;
    }

    setLoadingProgress(step.id);
    const response = await ProjectApi.updateStepProgress(step.id, r);
    setLoadingProgress(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    await onEdit();
  };

  const addStep = async () => {
    setLoadingCreateStep(true);
    const response = await ProjectApi.createEmptyStep(project.id);
    setLoadingCreateStep(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    await onEdit();
  };

  const addStepInside = async (s) => {
    setLoadingAddStepInside(s.id);
    const response = await ProjectApi.createEmptyStepInside(project.id, s.id);
    setLoadingAddStepInside(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    await onEdit();
  };

  const removeStep = async ({ id }) => {
    if (!confirm("Deseja realmente excluir esta etapa?")) {
      return;
    }

    setLoadingDeleteStep(id);
    const response = await ProjectApi.deleteStep(id);
    setLoadingDeleteStep(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    await onEdit();
  };

  const handleDates = (step) => {
    setCurrentStep(step);
    setChangingDates(true);
  };

  const renderStep = (step, level = 0) => {
    return (
      <SoftBox
        className={[step.children.length > 0 ? "fold-container" : ""].join(" ")}
        style={{ maxWidth: "100%" }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SoftBox
          style={{ marginTop: 10, paddingLeft: level * 30 }}
          lineHeight={1}
          key={step.id}
          className={[level > 0 ? "fold" : "", "line"].join(" ")}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <SoftTypography variant="caption" fontWeight="regular" color="text">
                <strong>Progresso:</strong> {step.progress}% | <strong>Datas:</strong>{" "}
                {!!step.pretty_estimated_initial_date && !!step.pretty_estimated_final_date && (
                  <span>
                    de {step.pretty_estimated_initial_date} a {step.pretty_estimated_final_date}
                  </span>
                )}
                {!step.pretty_estimated_initial_date && !step.pretty_estimated_final_date && (
                  <SoftTypography variant="caption" color="error">
                    Não definidas.
                  </SoftTypography>
                )}
              </SoftTypography>
              <SoftInput
                id={`techgest--input-eap-step-name-${step.id}`}
                name={`techgest--input-eap-step-name-${step.id}`}
                sx={{ flexBasis: 5000 }}
                placeholder="Nome da etapa"
                autoFocus={step.name.length == 0}
                defaultValue={step.name}
                className="z-index-2 name-input"
                onChange={(e) => onStepEdit(step, e)}
              />
              <SoftProgress
                value={step.progress}
                color={step.progress == 100 ? "success" : "info"}
                variant="gradient"
                label={false}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              className="z-index-2"
              display="flex"
              direction="column"
            >
              <SoftBox>
                <SoftTypography variant="caption" fontWeight="regular" color="text">
                  Custo estimado:
                  {step.children.length <= 0 && (
                    <React.Fragment> ({step.estimatedCost.asMoney()})</React.Fragment>
                  )}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                {step.children.length <= 0 ? (
                  <React.Fragment>
                    <SoftButton
                      color="white"
                      variant="contained"
                      onClick={() => {
                        setCurrentStep(step);
                        setForecasting(true);
                      }}
                    >
                      Ver
                    </SoftButton>
                    <SoftButton
                      color="success"
                      variant="contained"
                      onClick={() => {
                        setCurrentStep(step);
                        setAddingForecast(true);
                      }}
                      disabled={AuthService.isBlocked()}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </SoftButton>
                  </React.Fragment>
                ) : (
                  <SoftTypography variant="h6" fontWeight="bold" color="info">
                    {step.estimatedCost.asMoney()}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>

            <Grid item xs={12} sm={12} md={3} className="z-index-2" style={{ textAlign: "right" }}>
              <SoftTypography variant="caption" fontWeight="regular" color="text">
                Acoes
              </SoftTypography>
              <br />
              {step.children.length == 0 && (
                <React.Fragment>
                  <SoftButton
                    variant="contained"
                    color={step.progress == 100 ? "success" : "info"}
                    onClick={() => handleDates(step)}
                    title="Atualizar datas"
                    disabled={AuthService.isBlocked()}
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </SoftButton>
                  <SoftButton
                    variant="contained"
                    color={step.progress == 100 ? "success" : "info"}
                    onClick={() => handleProgress(step)}
                    title="Atualizar progresso"
                    disabled={AuthService.isBlocked()}
                  >
                    <FontAwesomeIcon
                      icon={
                        loadingProgress && loadingProgress == step.id ? faSpinner : faPercentage
                      }
                      spin={loadingProgress && loadingProgress == step.id}
                    />
                  </SoftButton>
                  <SoftButton
                    variant="contained"
                    color="white"
                    onClick={() => removeStep(step)}
                    disabled={
                      (loadingDeleteStep && loadingDeleteStep == step.id) || AuthService.isBlocked()
                    }
                  >
                    <SoftTypography color="error">
                      <FontAwesomeIcon
                        icon={
                          loadingDeleteStep && loadingDeleteStep == step.id ? faSpinner : faTrash
                        }
                        spin={loadingDeleteStep && loadingDeleteStep == step.id}
                      />
                    </SoftTypography>
                  </SoftButton>
                </React.Fragment>
              )}
              <SoftButton
                variant="contained"
                color="white"
                onClick={() => addStepInside(step)}
                disabled={AuthService.isBlocked()}
              >
                <FontAwesomeIcon
                  icon={
                    loadingAddStepInside && loadingAddStepInside == step.id ? faSpinner : faPlus
                  }
                  spin={loadingAddStepInside && loadingAddStepInside == step.id}
                />
                <FontAwesomeIcon icon={faArrowDown} style={{ marginLeft: 10 }} />
              </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
        {step.children.length > 0 && step.children.map((c) => renderStep(c, level + 1))}
      </SoftBox>
    );
  };

  const onStepEdit = (s, e) => {
    if (inputsRef.current[s.id]) {
      clearTimeout(inputsRef.current[s.id]);
    }

    const timeoutId = setTimeout(async () => {
      const form = e.target.closest("form");
      const name = form[`techgest--input-eap-step-name-${s.id}`].value || "";
      // const estimatedCost = form[`techgest--input-eap-step-estimatedCost-${s.id}`].value || "";
      const estimatedCost = s.estimatedCost;

      const response = await ProjectApi.updateStep(s.id, {
        name,
        estimatedCost,
      });

      if (!response.ok) {
        console.error(response.message);
      }

      setCurrentStep(null);
    }, 1000);

    inputsRef.current[s.id] = timeoutId;
  };

  return (
    <SoftBox id="eap-edit-container" style={{ position: "relative" }}>
      <ForecastListModal
        step={currentStep}
        showing={forecasting}
        onClose={() => {
          setCurrentStep(null);
          setForecasting(false);
        }}
        onAddClicked={() => {
          setAddingForecast(true);
        }}
        onDelete={() => {
          onEdit();
        }}
      />

      <SimpleForecastModal
        step={currentStep}
        showing={addingForecast}
        onClose={() => {
          setCurrentStep(null);
          setAddingForecast(false);
        }}
        onSuccess={({ keepEditing }) => {
          onEdit();

          if (!keepEditing) {
            setAddingForecast(false);
          }
        }}
      />

      <DatesModal
        step={currentStep}
        showing={changingDates}
        onClose={() => {
          setCurrentStep(null);
          setChangingDates(false);
        }}
        onSuccess={() => {
          onEdit();
          setCurrentStep(null);
          setChangingDates(false);
        }}
      />

      <SoftBox py={3} px={3} borderBottom={1} borderColor={colors.grey["300"]}>
        <SoftBox
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          lineHeight={1}
        >
          {steps.map((s) => renderStep(s))}

          <SoftButton
            variant="outlined"
            color="black"
            style={{ marginTop: 20 }}
            onClick={addStep}
            disabled={loadingCreateStep || AuthService.isBlocked()}
          >
            {!loadingCreateStep && <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />}
            {loadingCreateStep && (
              <FontAwesomeIcon icon={faSpinner} style={{ marginRight: 10 }} spin={true} />
            )}
            Adicionar etapa
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default EapEdit;
