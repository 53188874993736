import { HTTP_OK, HTTP_CREATED } from "config/constants/http";
import { BaseApi } from "./BaseApi";
import { IS_LOGGED_STORAGE_KEY } from "config/constants/auth";
import { USER_KEY } from "config/constants/auth";
import { ConfigurationService } from "./Configuration";

const USER_STATUS_BLOCKED = "blocked";
let globalUser = null;

export const AuthService = {
  isLogged: () => {
    const isLoggedValue = localStorage.getItem(IS_LOGGED_STORAGE_KEY);
    return isLoggedValue && isLoggedValue == 1;
  },
  isBlocked: () => {
    const user = AuthService.user();

    if (!user) return false;

    return user.status === USER_STATUS_BLOCKED;
  },
  setIsLogged: (value) => {
    localStorage.setItem(IS_LOGGED_STORAGE_KEY, Number(value));
    if (Number(value) == 0) {
      localStorage.removeItem(USER_KEY);
    }
  },
  user: (data = null) => {
    if (data) {
      localStorage.setItem(USER_KEY, JSON.stringify(data));
      return;
    }

    if (globalUser) return globalUser;
    const user = localStorage.getItem(USER_KEY);

    if (!user) {
      return {
        name: "",
        status: "blocked",
      };
    }

    globalUser = JSON.parse(user);
    return globalUser;
  },
};

export const AuthApi = {
  logout: async () => {
    try {
      const response = await BaseApi.make("/api/auth/logout", "DELETE");

      if (response.status != HTTP_OK) {
        console.error(response.data.message);
        // throw new Error("Falha ao sair. Por favor, limpe os cookies e tente novamente");
      }

      AuthService.setIsLogged(0);
      ConfigurationService.clear();

      return {
        ok: true,
      };
    } catch (e) {
      return {
        ok: false,
        message: e.message,
      };
    }
  },
  login: async (username, password) => {
    try {
      const response = await BaseApi.make("/api/auth/login", "POST", {
        username,
        password,
      });

      if (response.status != HTTP_OK) {
        throw new Error(response.data.message);
      }

      AuthService.setIsLogged(1);
      AuthService.user(response.data.user);

      return {
        ok: true,
        message: "",
      };
    } catch (error) {
      console.error("Failed to login:", { error });
      return {
        ok: false,
        message: error.message,
      };
    }
  },
  register: async ({ name, email, username, password, passwordConfirmation }) => {
    try {
      const response = await BaseApi.make("/api/auth/register", "POST", {
        name,
        email,
        username,
        password,
        password_confirmation: passwordConfirmation,
      });

      if (response.status != HTTP_CREATED) {
        throw new Error(response.data.message);
      }

      AuthService.setIsLogged(1);
      AuthService.user(response.data.user);

      return {
        ok: true,
        message: "",
      };
    } catch (error) {
      console.error("Failed to register:", { error });
      return {
        ok: false,
        message: error.message,
      };
    }
  },
};
