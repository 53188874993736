import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { AVAILABLE_NATURES, NATURE_TAXES } from "constants/nature";
import { useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { StepApi } from "services/Step";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
  minWidth: "40vw",
};

export const SimpleForecastModal = ({ step, showing, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const total = e.target["techgest--input-total"].value;
    const nature = e.target["techgest--input-nature"].value;
    const description = e.target["techgest--input-description"].value;
    const checked = e.target["techgest--input-keepEditing"].checked;

    setLoading(true);
    const response = await StepApi.storeSimpleForecast(step.id, {
      total,
      nature,
      description,
    });
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    onSuccess({
      keepEditing: !!checked,
    });
  };

  if (!showing || !step) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <SoftTypography variant="p" fontSize="22">
          Novo custo estimado - {step.name}
        </SoftTypography>
        <br />
        <SoftTypography variant="caption">
          Previsão atual: {step.estimatedCost.asMoney()}
        </SoftTypography>

        <Divider />

        {/* Forecast creation form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Valor:
                </SoftTypography>
              </SoftBox>
              <CurrencyInput
                onChangeValue={console.log}
                required
                placeholder="R$ 999,99"
                autofocus
                InputElement={
                  <SoftInput
                    type="text"
                    name="techgest--input-total"
                    id="techgest--input-total"
                    autofocus
                  />
                }
              />
            </SoftBox>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Natureza:
                </SoftTypography>
              </SoftBox>
              <Select
                name="techgest--input-nature"
                id="techgest--input-nature"
                required
                disabled={loading}
                defaultValue={NATURE_TAXES.id}
              >
                {AVAILABLE_NATURES.map((nature) => (
                  <MenuItem key={`nature-${nature.id}`} value={nature.id}>
                    {nature.name}
                  </MenuItem>
                ))}
              </Select>
            </SoftBox>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Descricão (opcional):
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-description"
                id="techgest--input-description"
                placeholder="Ex.: cartório"
              />
            </SoftBox>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <label htmlFor="techgest--input-keepEditing">
                <Checkbox
                  name="techgest--input-keepEditing"
                  id="techgest--input-keepEditing"
                  value="1"
                />
                <SoftTypography variant="caption">Salvar e continuar editando</SoftTypography>
              </label>
            </SoftBox>
          </SoftBox>

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Fechar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Registrar {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End forecast creation form */}
      </SoftBox>
    </Modal>
  );
};
