const handlers = {};

export const useEvents = () => {
  const dispatch = (event, message) => {
    if (!handlers[event]) {
      return;
    }

    if (!Array.isArray(handlers[event])) {
      return;
    }

    handlers[event].forEach(async (handler) => {
      if (typeof handler.callback !== "function") return;
      await handler.callback(message);
    });
  };

  const subscribe = (event, name, callback) => {
    if (!handlers[event] || !Array.isArray(handlers[event])) {
      handlers[event] = [];
    }

    if (handlers[event].find((h) => h.name === name)) {
      return;
    }

    handlers[event].push({
      name,
      callback,
    });
  };

  const unsubscribe = (event, handlerName) => {
    if (!handlers[event] || !Array.isArray(handlers[event])) {
      return;
    }

    handlers[event] = handlers[event].filter((h) => h.name !== handlerName);
  };

  return {
    dispatch,
    subscribe,
    unsubscribe,
  };
};
