import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { MaterialApi } from "services/Material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export const EditModal = ({ onClose, onSuccess, groups, showing, material }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const name = e.target["techgest--input-materialName"].value;
    const unit = e.target["techgest--input-materialUnit"].value;
    const price = e.target["techgest--input-materialPrice"].value;
    const group_id = e.target["techgest--input-groupId"].value;

    setLoading(true);
    const response = await MaterialApi.update(material.id, {
      name,
      unit,
      price,
      group_id,
    });
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    onSuccess();
  };

  if (!showing || Object.keys(material) < 1) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <p id="child-modal-description">{material.name} - Editar</p>

        <Divider />

        {/* Material creation form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Empresa:
              </SoftTypography>
            </SoftBox>
            <Select
              type="text"
              name="techgest--input-groupId"
              id="techgest--input-groupId"
              defaultValue={material.group_id}
              required
            >
              <MenuItem value={0}>Selecione uma empresa sua</MenuItem>
              {groups.map((group) => {
                return (
                  <MenuItem key={`group-${group.id}`} value={group.id}>
                    {group.name}
                  </MenuItem>
                );
              })}
            </Select>
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Nome:
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="text"
              name="techgest--input-materialName"
              id="techgest--input-materialName"
              placeholder="Milheiro de bloco"
              defaultValue={material.name}
              required
            />
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} mr={1} style={{ flexBasis: "50%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Unidade:
                </SoftTypography>
              </SoftBox>
              <Select
                type="text"
                name="techgest--input-materialUnit"
                id="techgest--input-materialUnit"
                defaultValue={material.unit}
                required
              >
                <MenuItem value="un">Unidade</MenuItem>
                <MenuItem value="mg">mg</MenuItem>
                <MenuItem value="g">g</MenuItem>
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="ml">ml</MenuItem>
                <MenuItem value="l">litros</MenuItem>
                <MenuItem value="m">metros</MenuItem>
                <MenuItem value="m2">m2</MenuItem>
                <MenuItem value="m3">m3</MenuItem>
                <MenuItem value="km">km</MenuItem>
              </Select>
            </SoftBox>
            <SoftBox mb={2} ml={1} style={{ flexBasis: "50%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Preco:
                </SoftTypography>
              </SoftBox>
              <CurrencyInput
                onChangeValue={console.log}
                defaultValue={material.price}
                InputElement={
                  <SoftInput
                    type="text"
                    name="techgest--input-materialPrice"
                    id="techgest--input-materialPrice"
                    placeholder="opcional"
                  />
                }
              />
            </SoftBox>
          </SoftBox>

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Cancelar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Salvar alteracoes {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End material creation form */}
      </SoftBox>
    </Modal>
  );
};
