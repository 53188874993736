import { HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const IssueApi = {
  list: async (limit, page, { type, projectId } = { groupId: 0, projectId: 0, type: "" }) => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("page", page);

    if (!!type) {
      params.append("type", type);
    }

    if (projectId > 0) {
      params.append("project_id", projectId);
    }

    const response = await BaseApi.make("/api/issues?" + params, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      ...response.data,
    };
  },
};
