import { HTTP_CREATED, HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const GroupApi = {
  getById: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      group: response.data.group,
    };
  },
  mine: async () => {
    const response = await BaseApi.make("/api/groups/mine", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      groups: response.data.groups,
    };
  },
  list: async () => {
    const response = await BaseApi.make("/api/groups", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      ...response.data,
    };
  },
  create: async (data) => {
    const response = await BaseApi.make("/api/groups", "POST", {
      ...data,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
      group: response.data.group,
    };
  },
  update: async (id, data) => {
    const response = await BaseApi.make("/api/groups/" + id, "PUT", {
      ...data,
    });

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  destroy: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id, "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  leave: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id + "/leave", "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      group: response.data.group,
    };
  },
  enter: async (code) => {
    const response = await BaseApi.make("/api/groups/enter", "POST", {
      code,
    });

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  open: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id + "/open", "PATCH");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  close: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id + "/close", "PATCH");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  regenerateCode: async (id) => {
    const response = await BaseApi.make("/api/groups/" + id + "/regenerate-code", "POST");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  updateRole: async (groupId, userId, role) => {
    const response = await BaseApi.make(
      "/api/groups/" + groupId + "/update-role/" + userId,
      "PUT",
      {
        role,
      }
    );

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  detachUser: async (groupId, userId) => {
    const response = await BaseApi.make("/api/groups/" + groupId + "/detach/" + userId, "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
};
