/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./prototypes.js";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { ErrorBoundary } from "react-error-boundary";
import SoftBox from "components/SoftBox/index.js";
import { Card } from "@mui/material";
import SoftTypography from "components/SoftTypography/index.js";

const ErrorFallback = () => {
  return (
    <SoftBox p={10} alignItems="center" justifyContent="center" display="flex">
      <Card>
        <SoftTypography variant="h4" color="error">
          Opa!
        </SoftTypography>
        <SoftTypography variant="p" color="text">
          Tivemos um erro inesperado. Você pode ver os detalhes no console caso queira reportar a um
          desenvolvedor. Caso contrário, espere e logo estaremos de volta :D
        </SoftTypography>
      </Card>
    </SoftBox>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <ErrorBoundary fallback={<ErrorFallback />}>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
