import { faMoneyBill, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import colors from "assets/theme/base/colors";
import TimelineItem from "examples/Timeline/TimelineItem";

export const Transaction = ({ transaction, project }) => {
  const date = new Date(transaction.created_at).toLocaleString("pt-BR");

  let projectText = "";

  if (transaction.project || project) {
    const name = transaction.project ? transaction.project.name : project.name;
    projectText = ` - Obra: ${name}`;
  }

  if (transaction.issue && transaction.material) {
    projectText = ` - Compra de material: ${transaction.material.name} (${transaction.issue.amount}${transaction.issue.unit})`;
  }

  const directionText = transaction.type == "income" ? "+" : "-";

  return (
    <TimelineItem
      color={transaction.type === "income" ? colors.success.main : colors.error.main}
      icon={transaction.type === "income" ? faMoneyCheck : faMoneyBill}
      title={`${directionText}${transaction.amount.asMoney()}`}
      dateTime={`${date}${projectText} / Por: ${transaction.user.name}`}
      badges={transaction.reason && transaction.reason.length > 0 ? [transaction.reason] : []}
    />
  );
};
