import { HTTP_OK, HTTP_CREATED } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const StepApi = {
  listForecasts: async (id) => {
    const response = await BaseApi.make("/api/steps/" + id + "/forecasts", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  storeSimpleForecast: async (id, payload) => {
    const response = await BaseApi.make("/api/steps/" + id + "/simple-forecast", "POST", {
      ...payload,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  storeForecasts: async (id, payload) => {
    const response = await BaseApi.make("/api/steps/" + id + "/forecasts", "POST", {
      ...payload,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
    };
  },
  deleteForecast: async (id) => {
    const response = await BaseApi.make("/api/steps/forecasts/" + id, "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  updateDates: async (id, estimatedInitialDate, estimatedFinalDate) => {
    const response = await BaseApi.make(`/api/steps/${id}/dates`, "PUT", {
      estimatedInitialDate,
      estimatedFinalDate,
    });

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
};
