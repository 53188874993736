import { faSpinner, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Divider, Grid, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEvents } from "hooks/useEvents";
import React, { useEffect, useMemo, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { ConfigurationService } from "services/Configuration";
import { ProjectApi } from "services/Project";
import { TransactionApi } from "services/Transaction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export const MovementModal = ({ onClose, groups, showing, onSuccess, projectId = null }) => {
  const [type, setType] = useState("income");
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [groupId, setGroupId] = useState(groups[0] ? groups[0].id : 0);

  const { dispatch } = useEvents();

  const onSubmit = async (e) => {
    e.preventDefault();

    const amount = e.target["techgest--input-amount"].value;
    const reason = e.target["techgest--input-reason"].value;
    const group = projectId ? 0 : e.target["techgest--input-groupId"].value;
    const project = projectId ? projectId : e.target["techgest--input-projectId"].value;
    const checked = e.target["techgest--input-keepCreating"].checked;

    setLoading(true);
    const response = await TransactionApi.store(group, project, {
      amount,
      reason,
      type,
    });
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    dispatch("transaction-stored", {});
    onSuccess({
      keepCreating: checked,
    });
  };

  const fetchProjects = async () => {
    if (projectId) {
      return;
    }

    const response = await ProjectApi.list(groupId);

    if (!response.ok) {
      error(response.message);
      return;
    }

    setProjects(response.projects);
  };

  const defaultProjectId = useMemo(() => {
    if (projects.length < 1) {
      return 0;
    }

    return projects[0].id;
  }, [projects]);

  const defaultGroupId = useMemo(() => {
    if (groups.length < 1) {
      return 0;
    }

    if (ConfigurationService.group()) {
      return ConfigurationService.group().id;
    }

    return groups[0].id;
  }, [groups]);

  useEffect(() => {
    fetchProjects();
  }, [groupId]);

  if (!showing) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <SoftButton style={{ position: "absolute", right: 10, top: 10 }} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </SoftButton>
        <p id="child-modal-description">Nova transacao</p>

        <Divider />

        {/* Material creation form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          {!projectId && (
            <React.Fragment>
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Empresa:
                  </SoftTypography>
                </SoftBox>
                <Select
                  type="text"
                  name="techgest--input-groupId"
                  id="techgest--input-groupId"
                  defaultValue={defaultGroupId}
                  onChange={(e) => {
                    setGroupId(Number(e.target.value));
                  }}
                  required
                >
                  {groups.map((group) => {
                    return (
                      <MenuItem key={`group-${group.id}`} value={group.id}>
                        {group.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Obra:
                  </SoftTypography>
                </SoftBox>
                <Select
                  type="text"
                  name="techgest--input-projectId"
                  id="techgest--input-projectId"
                  defaultValue={defaultProjectId}
                  required
                >
                  <MenuItem value="0">Nenhum</MenuItem>
                  {projects.map((project) => {
                    return (
                      <MenuItem key={`group-${project.id}`} value={project.id}>
                        {project.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </SoftBox>
            </React.Fragment>
          )}

          <SoftBox py={4}>
            <Grid container>
              <Grid item xs={6} sm={6} lg={6}>
                <SoftButton
                  style={{ width: "100%" }}
                  onClick={() => setType("income")}
                  {...(type == "income"
                    ? { color: "success", variant: "gradient" }
                    : { color: "white", variant: "contained" })}
                >
                  Receita
                </SoftButton>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <SoftButton
                  style={{ width: "100%" }}
                  onClick={() => setType("outcome")}
                  {...(type == "outcome"
                    ? { color: "error", variant: "gradient" }
                    : { color: "white", variant: "contained" })}
                >
                  Despesa
                </SoftButton>
              </Grid>
            </Grid>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Valor:
                </SoftTypography>
              </SoftBox>
              <CurrencyInput
                onChangeValue={console.log}
                defaultValue={1}
                required
                InputElement={
                  <SoftInput
                    type="text"
                    name="techgest--input-amount"
                    id="techgest--input-amount"
                  />
                }
              />
            </SoftBox>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Justificativa (opcional):
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-reason"
                id="techgest--input-reason"
                placeholder={type == "income" ? "Ex.: sinal do comprador" : "Ex.: mão de obra"}
              />
            </SoftBox>
          </SoftBox>

          <Divider />

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <label htmlFor="techgest--input-keepCreating">
                <Checkbox
                  name="techgest--input-keepCreating"
                  id="techgest--input-keepCreating"
                  value="1"
                />
                <SoftTypography variant="caption">Salvar e continuar adicionando</SoftTypography>
              </label>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Fechar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Registrar {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End material creation form */}
      </SoftBox>
    </Modal>
  );
};
