import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { AVAILABLE_NATURES, NATURE_TAXES } from "constants/nature";
import { SimpleTable } from "layouts/simple-table";
import React, { useEffect, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { AuthService } from "services/Auth";
import { StepApi } from "services/Step";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
  minWidth: "40vw",
};

export const ForecastListModal = ({ step, onClose, onAddClicked, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [forecasts, setForecasts] = useState([]);

  const fetchForecasts = async () => {
    if (!step) {
      setForecasts([]);
      return;
    }

    setLoading(true);
    const response = await StepApi.listForecasts(step.id);
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    setForecasts(response.forecasts);
  };

  const deleteForecast = async (forecast) => {
    if (!confirm("Deseja realmente excluir esta estimativa de custo?")) {
      return;
    }

    setDeleting(forecast.id);
    const response = await StepApi.deleteForecast(forecast.id);
    setDeleting(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    // TODO: remover essa gambs
    step.estimatedCost -= forecast.total;

    success(response.message);
    fetchForecasts();
    onDelete();
  };

  useEffect(() => {
    fetchForecasts();
  }, [step]);

  if (!step) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <SoftBox display="flex" direction="row" justifyContent="space-between">
          <SoftBox>
            <SoftTypography variant="p" fontSize="22">
              Previsão de Custos - {step.name}
              {loading && <FontAwesomeIcon spin icon={faSpinner} />}
            </SoftTypography>
            <br />
            <SoftTypography variant="caption">
              Valor total: {step.estimatedCost.asMoney()}
            </SoftTypography>
          </SoftBox>
          <SoftBox>
            <SoftButton color="success" onClick={onAddClicked} disabled={AuthService.isBlocked()}>
              <FontAwesomeIcon icon={faPlus} />
            </SoftButton>
          </SoftBox>
        </SoftBox>

        <SimpleTable
          header={["#", "Natureza", "Descricao", "Valor", ""]}
          title=""
          items={forecasts}
          searchPlaceholder="Busque por natureza ou descricao.."
          keyResolver={(forecast) => `forecast-${forecast.id}`}
          onFilter={(forecast, term) =>
            forecast.pretty_nature.includes(term) ||
            (forecast.description.length > 0 && forecast.description.includes(term))
          }
          resolvers={[
            (_, i) => (
              <SoftTypography variant="caption" textAlign="center">
                {i + 1}
              </SoftTypography>
            ),
            (forecast) => (
              <SoftTypography variant="caption">{forecast.pretty_nature}</SoftTypography>
            ),
            (forecast) => <SoftTypography variant="caption">{forecast.description}</SoftTypography>,
            (forecast) => (
              <SoftTypography variant="caption">{forecast.total.asMoney()}</SoftTypography>
            ),
            (forecast) => (
              <React.Fragment>
                <SoftButton
                  color="error"
                  onClick={() => deleteForecast(forecast)}
                  disabled={loading || deleting}
                >
                  <FontAwesomeIcon
                    spin={deleting && deleting == forecast.id}
                    icon={deleting && deleting == forecast.id ? faSpinner : faTrash}
                  />
                </SoftButton>
              </React.Fragment>
            ),
          ]}
        />
      </SoftBox>
    </Modal>
  );
};
