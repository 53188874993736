// @mui group components
import Card from "@mui/material/Card";
import { Divider, Grid, MenuItem, Modal, Select } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import React, { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import typography from "assets/theme/base/typography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEdit,
  faExternalLinkAlt,
  faRefresh,
  faSpinner,
  faTimes,
  faTrash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { GroupApi } from "services/Group";
import { AuthService } from "services/Auth";
import { CurrencyInput } from "react-currency-mask";
import { SimpleTable } from "layouts/simple-table";
import { useEvents } from "hooks/useEvents";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
  maxWidth: "100vw",
};

function Groups() {
  const ME = AuthService.user();
  const [creating, setCreating] = useState(false);
  const [groups, setGroups] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [entering, setEntering] = useState(null);
  const [regenerating, setRegenerating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [leaving, setLeaving] = useState(null);
  const [opening, setOpening] = useState(null);
  const [closing, setClosing] = useState(null);
  const [detaching, setDetaching] = useState(null);
  const [updatingRole, setUpdatingRole] = useState(null);

  const { dispatch } = useEvents();

  const handleGroupCreation = async (e) => {
    e.preventDefault();
    setCreating(true);

    const name = e.target["techgest--input-groupName"].value;
    const initialBalance = e.target["techgest--input-groupInitialBalance"].value;

    const response = await GroupApi.create({
      name,
      initialBalance,
    });

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    setCreating(false);
    fetchGroups();
    dispatch("group-created", {});
  };

  const handleGroupUpdating = async (e) => {
    e.preventDefault();
    setEditing(true);

    const name = e.target["techgest--input-groupName"].value;
    const response = await GroupApi.update(currentGroup.id, {
      name,
    });

    if (!response.ok) {
      error(response.message);
      return;
    }

    setCurrentGroup(response.group);
    fetchGroups();
    dispatch("group-updated", { group });
  };

  const deleteGroup = async (group) => {
    if (!confirm(`Deseja realmente excluir a empresa "${group.name}"?`)) {
      return;
    }

    setDeleting(group.id);
    const response = await GroupApi.destroy(group.id);
    setDeleting(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
    dispatch("group-deleted", { group });
  };

  const enterGroup = async () => {
    const code = String(prompt("Digite o código da empresa:") ?? "");

    if (code.length < 1) {
      return;
    }

    setEntering(true);
    const response = await GroupApi.enter(code);
    setEntering(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
  };

  const leaveGroup = async (group) => {
    if (!confirm(`Deseja realmente sair da empresa "${group.name}"?`)) {
      return;
    }

    setLeaving(group.id);
    const response = await GroupApi.leave(group.id);
    setLeaving(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
  };

  const regenerateCode = async (id) => {
    if (!confirm("Tem certeza que quer gerar novamente o código de convite?")) {
      return;
    }

    setRegenerating(id);
    const response = await GroupApi.regenerateCode(id);
    setRegenerating(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
  };

  const open = async (id) => {
    if (!confirm("Deseja realmente abrir a empresa para novos integrantes?")) {
      return;
    }

    setOpening(id);
    const response = await GroupApi.open(id);
    setOpening(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
  };

  const close = async (id) => {
    if (!confirm("Deseja realmente fechar a empresa para novos integrantes?")) {
      return;
    }

    setOpening(id);
    const response = await GroupApi.close(id);
    setOpening(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchGroups();
  };

  const detachUser = async (userId) => {
    if (!confirm("Deseja realmente remover esse integrante da empresa?")) {
      return;
    }

    setDetaching(userId);
    const response = await GroupApi.detachUser(currentGroup.id, userId);
    setDetaching(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    setCurrentGroup(response.group);
    fetchGroups();
  };

  const fetchGroups = async () => {
    const response = await GroupApi.list();

    if (!response.ok) {
      console.error(response);
      return;
    }

    setGroups(response.groups);
    setAvailableRoles(response.availableRoles);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "empresas",
            uri: "/empresas",
          },
        ]}
      />

      <Modal
        open={creating}
        onClose={() => setCreating(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <SoftBox sx={{ ...style }}>
          <SoftButton
            style={{ position: "absolute", right: 10, top: 10 }}
            onClick={() => {
              setCreating(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </SoftButton>
          <p id="child-modal-description">Cadastrar empresa</p>

          <Divider />

          {/* Group creation form */}
          <SoftBox component="form" role="form" onSubmit={handleGroupCreation}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-groupName"
                id="techgest--input-groupName"
                placeholder="P&G Investimentos"
                required
              />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Saldo inicial (R$):
                </SoftTypography>
              </SoftBox>
              <CurrencyInput
                onChangeValue={console.log}
                defaultValue={"0"}
                required
                InputElement={
                  <SoftInput
                    type="text"
                    name="techgest--input-groupInitialBalance"
                    id="techgest--input-groupInitialBalance"
                    required
                  />
                }
              />
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setCreating(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          {/* End group creation form */}
        </SoftBox>
      </Modal>

      <Modal
        open={editing}
        onClose={() => {
          setEditing(false);
          setCurrentGroup({});
        }}
        aria-labelledby="edicao-de-group"
        aria-describedby="edicao-de-group"
      >
        <SoftBox sx={{ ...style, minWidth: "50vw" }}>
          <p id="child-modal-description">{currentGroup.name}</p>

          <Divider />

          {/* Group editing form */}
          <SoftBox component="form" role="form" onSubmit={handleGroupUpdating}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-groupName"
                id="techgest--input-groupName"
                placeholder="Milheiro de bloco"
                defaultValue={currentGroup.name}
                required
              />
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setEditing(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <Card style={{ marginTop: 10 }}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Detalhes</SoftTypography>
            </SoftBox>

            <SoftBox p={3}>
              <SoftTypography variant="caption">
                {currentGroup.projects_count} projetos
              </SoftTypography>
              <br />
              <SoftTypography variant="caption">
                {editing ? currentGroup.users.length : 0} integrantes
              </SoftTypography>
              <br />
              <SoftTypography variant="caption">Código: {currentGroup.code}</SoftTypography>
            </SoftBox>
          </Card>

          <Card style={{ marginTop: 10 }}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              style={{ marginBottom: 10 }}
            >
              <SoftTypography variant="h6">Integrantes</SoftTypography>
            </SoftBox>

            {editing &&
              currentGroup.users.map((user, index) => (
                <Card key={`user-${user.id}`}>
                  <Grid container p={3} spacing={0}>
                    <Grid item lg={10} md={10} sm={12}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center"
                      >
                        <SoftTypography variant="p" fontSize={17}>
                          {user.name} {ME.id == user.id && <small>(eu)</small>}
                          <br />
                        </SoftTypography>
                      </SoftBox>
                      {ME.id !== user.id && (
                        <SoftButton
                          color="error"
                          onClick={() => detachUser(user.id)}
                          disabled={!!detaching}
                        >
                          <FontAwesomeIcon
                            icon={detaching && detaching == user.id ? faSpinner : faTrash}
                            size="xs"
                            spin={detaching && detaching == user.id}
                          />
                        </SoftButton>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      {ME.id == user.id ? (
                        <SoftTypography variant="caption">Dono</SoftTypography>
                      ) : (
                        <>
                          <SoftTypography variant="caption">
                            {user.pivot.role.split(",").length} permissoes
                          </SoftTypography>
                          <Select
                            name="techgest--input-role"
                            id="techgest--input-role"
                            required
                            multiple
                            onChange={async (event) => {
                              const role = event.target.value.filter((v) => !!v);
                              setUpdatingRole(user.id);
                              const response = await GroupApi.updateRole(
                                currentGroup.id,
                                user.id,
                                role
                              );
                              setUpdatingRole(null);

                              if (!response.ok) {
                                error(response.message);
                                return;
                              }

                              setCurrentGroup(response.group);
                              setAvailableRoles(response.availableRoles);
                            }}
                            disabled={updatingRole}
                            value={user.pivot.role.split(",")}
                            endDecorator={
                              updatingRole && updatingRole == user.id ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                              ) : null
                            }
                          >
                            {availableRoles.map((role) => (
                              <MenuItem key={`role-${role.id}`} value={role.id}>
                                {role.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              ))}
          </Card>
          {/* End group editing form */}
        </SoftBox>
      </Modal>

      <SoftBox py={3}>
        {groups.length <= 0 && (
          <SoftButton color="success" onClick={() => setCreating(true)} style={{ marginRight: 10 }}>
            + Cadastrar
          </SoftButton>
        )}

        {false && (
          <SoftButton color="white" variant="contained" onClick={enterGroup} disabled={entering}>
            Entrar <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
          </SoftButton>
        )}
      </SoftBox>
      <SoftBox py={3}>
        <SimpleTable
          title="Empresas"
          header={["#", "Nome", "Saldo", "Acao"]}
          keyResolver={(group) => `group-${group.id}`}
          items={groups}
          resolvers={[
            (_, index) => <SoftTypography variant="caption">#{index + 1}</SoftTypography>,
            (group) => (
              <React.Fragment>
                <SoftTypography
                  fontWeight="regular"
                  fontSize={typography.size.sm}
                  color="secondary"
                  sx={{
                    display: "inline-block",
                    width: "max-content",
                  }}
                >
                  {group.name}
                </SoftTypography>
                <br />
                <SoftTypography
                  fontWeight="regular"
                  fontSize={typography.size.xxs}
                  color="secondary"
                  sx={{
                    display: "inline-block",
                    width: "max-content",
                  }}
                >
                  {group.projects_count} projeto(s)
                </SoftTypography>
              </React.Fragment>
            ),
            (group) => (
              <SoftTypography
                component="label"
                variant="caption"
                color={group.balance < 0 ? "error" : "success"}
                fontWeight={group.balance < 0 ? "bold" : "normal"}
              >
                {group.balance.asMoney()}
              </SoftTypography>
            ),
            (group) => (
              <React.Fragment>
                {ME.id === group.user_id && (
                  <SoftButton
                    color="white"
                    onClick={() => regenerateCode(group.id)}
                    disabled={regenerating && regenerating == group.id}
                  >
                    <FontAwesomeIcon
                      icon={regenerating && regenerating == group.id ? faSpinner : faRefresh}
                      spin={regenerating && regenerating == group.id}
                    />
                  </SoftButton>
                )}
                {ME.id === group.user_id && (
                  <SoftButton
                    color="white"
                    onClick={() => {
                      setEditing(true);
                      setCurrentGroup(group);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </SoftButton>
                )}
                {ME.id === group.user_id && (
                  <SoftButton
                    color="error"
                    onClick={() => deleteGroup(group)}
                    disabled={deleting && deleting == group.id}
                  >
                    <FontAwesomeIcon
                      icon={deleting && deleting == group.id ? faSpinner : faTrashCan}
                      spin={deleting && deleting == group.id}
                    />
                  </SoftButton>
                )}
                {ME.id !== group.user_id && (
                  <SoftButton
                    color="error"
                    onClick={() => leaveGroup(group)}
                    disabled={leaving && leaving == group.id}
                  >
                    <FontAwesomeIcon
                      icon={leaving && leaving == group.id ? faSpinner : faExternalLinkAlt}
                      spin={leaving && leaving == group.id}
                    />
                  </SoftButton>
                )}
              </React.Fragment>
            ),
          ]}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Groups;
