import { HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";
import { HTTP_CREATED } from "config/constants/http";
import { HTTP_ACCEPTED } from "config/constants/http";

export const DocumentApi = {
  list: async (limit, page, { type, projectId } = { groupId: 0, projectId: 0, type: "" }) => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("page", page);

    if (!!type) {
      params.append("type", type);
    }

    if (projectId > 0) {
      params.append("project_id", projectId);
    }

    const response = await BaseApi.make("/api/documents?" + params, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  store: async (name, file, projectId, groupId = null) => {
    const data = new FormData();
    data.append("name", name);
    data.append("file", file);
    data.append("project_id", projectId);
    !!groupId && data.append("group_id", groupId);

    const response = await BaseApi.make("/api/documents", "POST", data, {
      isJSON: false,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
  destroy: async (id) => {
    const response = await BaseApi.make("/api/documents/" + id, "DELETE");

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
};
