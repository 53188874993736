import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "services/Auth";
import { AuthService } from "services/Auth";

export const useAuth = () => {
  const navigate = useNavigate();

  const logout = async () => {
    const response = await AuthApi.logout();

    if (!response.ok) {
      console.error(response.message);
      // return;
    }

    window.location.href = "/login";
  };

  useLayoutEffect(() => {
    const isLogged = AuthService.isLogged();

    if (!isLogged) {
      window.location.href = "/login";
    }
  }, []);

  return {
    logout,
  };
};
