import { HTTP_CREATED, HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const MaterialApi = {
  getById: async (id) => {
    const response = await BaseApi.make("/api/materials/" + id, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
      material: response.data.material,
    };
  },
  list: async () => {
    const response = await BaseApi.make("/api/materials", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      materials: response.data.materials,
    };
  },
  create: async (data) => {
    const response = await BaseApi.make("/api/materials", "POST", {
      ...data,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
      material: response.data.material,
    };
  },
  update: async (id, data) => {
    const response = await BaseApi.make("/api/materials/" + id, "PUT", {
      ...data,
    });

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
      material: response.data.material,
    };
  },
  destroy: async (id) => {
    const response = await BaseApi.make("/api/materials/" + id, "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
      material: response.data.material,
    };
  },
  issueToStock: async (id, quantity, total, reason, payload) => {
    const response = await BaseApi.make("/api/materials/" + id + "/stock", "POST", {
      quantity,
      total,
      reason,
      ...payload,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
    };
  },
  issue: async (projectId, payload) => {
    const response = await BaseApi.make("/api/materials/issue", "POST", {
      project_id: projectId,
      ...payload,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: response.data.message,
    };
  },
  issues: async () => {
    const response = await BaseApi.make("/api/materials/issues", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      ...response.data,
    };
  },
};
