import { faDownload, faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Unsubscribe } from "@mui/icons-material";
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useEvents } from "hooks/useEvents";
import { SimpleTable } from "layouts/simple-table";
import React, { useEffect, useState } from "react";
import { DocumentApi } from "services/Document";

const LIMIT = 200;

export const DocumentsContainer = ({ projectId }) => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [documents, setDocuments] = useState([]);

  const { subscribe, unsubscribe } = useEvents();

  const fetchDocuments = async () => {
    setLoading(true);
    const response = await DocumentApi.list(LIMIT, page, {
      projectId,
    });
    setLoading(false);

    if (!response.ok) {
      error("Falha ao obter a lista de movimentacoes");
      return;
    }

    setData(response.documents);

    if (page == 1) {
      setDocuments(response.documents.data);
      return;
    }

    setDocuments((old) => [...old, ...response.documents.data]);
  };

  const deleteDocument = async (doc) => {
    if (!confirm(`Deseja realmente excluir o documento "${doc.name}"?`)) {
      return;
    }

    setDeleting(doc.id);
    const response = await DocumentApi.destroy(doc.id);
    setDeleting(null);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    fetchDocuments();
  };

  useEffect(() => {
    fetchDocuments();

    subscribe("document-uploaded", "DocumentsContainer", () => {
      fetchDocuments();
    });

    return () => {
      unsubscribe("document-uploaded", "DocumentsContainer");
    };
  }, [projectId]);

  return (
    <Card className="h-100">
      <SoftBox pt={3} px={3}>
        <SoftTypography variant="h6" fontWeight="medium">
          Documentos
          {loading && <FontAwesomeIcon spin icon={faSpinner} style={{ marginLeft: 10 }} />}
          {!loading && data && ` (${data.total})`}
        </SoftTypography>
        <SoftBox p={2}>
          <SimpleTable
            header={["#", "Nome", "Acao"]}
            searchPlaceholder="Busque por nome ou usuário.."
            title=""
            onFilter={(doc, term) =>
              doc.name.toLowerCase().includes(term.toLowerCase()) ||
              (doc.user && doc.user.name.toLowerCase().includes(term.toLowerCase()))
            }
            items={documents}
            keyResolver={(doc, index) => `document-${doc.id}${index}`}
            resolvers={[
              (_, index) => `#${index + 1}`,
              (doc) => (
                <React.Fragment>
                  <SoftTypography variant="p" fontWeight="medium">
                    {doc.name} (.{doc.extension})
                  </SoftTypography>
                  <br />
                  <SoftTypography variant="caption" fontWeight="light">
                    Por: {doc.user.name}
                  </SoftTypography>
                </React.Fragment>
              ),
              (doc) => (
                <React.Fragment>
                  <SoftButton
                    color="success"
                    variant="contained"
                    href={doc.download_url}
                    download="download"
                    target="_blank"
                    style={{ marginRight: 10 }}
                  >
                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} /> Download
                  </SoftButton>
                  <SoftButton
                    color="error"
                    variant="contained"
                    onClick={() => deleteDocument(doc)}
                    disabled={deleting}
                  >
                    <FontAwesomeIcon
                      icon={deleting && deleting == doc.id ? faSpinner : faTrash}
                      spin={deleting && deleting == doc.id}
                    />
                  </SoftButton>
                </React.Fragment>
              ),
            ]}
          />
        </SoftBox>
      </SoftBox>

      <SoftButton
        color="info"
        disabled={(data && data.total <= documents.length) || loading}
        onClick={() => {
          setPage(page + 1);
        }}
      >
        <FontAwesomeIcon
          icon={loading ? faSpinner : faPlus}
          spin={loading}
          style={{ marginRight: 10 }}
        />
        Carregar mais{" "}
      </SoftButton>
    </Card>
  );
};
