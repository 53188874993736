export const NATURE_TAXES = {
  id: "taxes",
  name: "Taxas",
};

export const NATURE_MATERIALS = {
  id: "materials",
  name: "Material",
};

export const NATURE_WORKFORCE = {
  id: "workforce",
  name: "Mão de obra",
};

export const NATURE_REFACTORING = {
  id: "refactoring",
  name: "Reformas",
};

export const NATURE_OTHERS = {
  id: "others",
  name: "Outros",
};

export const AVAILABLE_NATURES = [
  NATURE_TAXES,
  NATURE_MATERIALS,
  NATURE_WORKFORCE,
  NATURE_REFACTORING,
  NATURE_OTHERS,
];
