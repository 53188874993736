import { X_XSRF_TOKEN_COOKIE_NAME, IS_LOGGED_STORAGE_KEY } from "config/constants/auth";
import { HTTP_UNAUTHORIZED } from "config/constants/http";
import { ConfigurationService } from "./Configuration";
import { AuthService } from "./Auth";

const BASE_HEADERS = {
  accept: "application/json",
  "x-app-version": "Techgest-Frontent--0.0.1-beta",
};

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const prepare = async () => {
  const token = getCookie(X_XSRF_TOKEN_COOKIE_NAME);

  if (token) {
    // return;
  }

  await fetch(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`, {
    credentials: "include",
  });
};

const defaultOptions = {
  headers: {},
  isJSON: true,
};

export const BaseApi = {
  make: async (url, method = "GET", body = {}, options = defaultOptions) => {
    await prepare();

    const extraParams = new URLSearchParams();
    const filters = ConfigurationService.filters();

    if (method === "GET") {
      if (!!filters.groupId && !url.includes("group_id")) {
        extraParams.append("group_id", filters.groupId);
      }

      if (!!filters.projectId && !url.includes("project_id")) {
        extraParams.append("project_id", filters.projectId);
      }
    }

    const finalUrl = [
      process.env.REACT_APP_API_BASE_URL,
      url,
      url.includes("?") ? "" : "?",
      "&",
      String(extraParams),
    ].join("");

    const parsedBody = options.isJSON ? JSON.stringify(body) : body;
    const finalHeaders = {
      ...BASE_HEADERS,
      ...options.headers,
      "X-XSRF-TOKEN": getCookie(X_XSRF_TOKEN_COOKIE_NAME),
    };

    if (options.isJSON) {
      finalHeaders["Content-Type"] = "application/json";
    }

    try {
      const response = await fetch(finalUrl, {
        method,
        headers: finalHeaders,
        body: method !== "GET" ? parsedBody : null,
        credentials: "include",
      });

      if (response.status == HTTP_UNAUTHORIZED && AuthService.isLogged()) {
        localStorage.removeItem(IS_LOGGED_STORAGE_KEY);
        window.location.href = "/entrar";
      }

      const data = await response.json();

      return {
        status: response.status,
        data,
      };
    } catch (e) {
      console.error(e);
      return {
        status: 500,
        data: {},
      };
    }
  },
};
