import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";

export const IssueCard = ({
  material,
  initialQuantity = 1,
  onChangeTotal,
  onChangeQuantity,
  onRemove,
  validateStock = false,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [total, setTotal] = useState(material.price);

  useEffect(() => {
    onChangeTotal(total);
    onChangeQuantity(quantity);
  }, [quantity, total]);

  return (
    <Grid container>
      <Grid item xl={8} lg={8} md={10} sm={12} style={{ marginBottom: 15 }}>
        <SoftBox>
          <SoftTypography variant="p" fontSize={17}>
            {total.asMoney()} - {material.name}
          </SoftTypography>
          <br />
          <SoftTypography variant="caption" fontSize={9}>
            {material.price.asMoney()}/{material.unit} - {material.stock} em estoque
          </SoftTypography>
          {/* <SoftButton
            color="white"
            onClick={() => onRemove(material)}
            size="xs"
            style={{ float: "right" }}
          >
            <FontAwesomeIcon icon={faTrash} size="xs" color="red" />
          </SoftButton> */}
        </SoftBox>
      </Grid>

      <Grid item xl={4} lg={8} md={6} sm={12}>
        <SoftInput
          name={`techgest--input-quantity-${material.id}`}
          id={`techgest--input-quantity-${material.id}`}
          required
          value={quantity}
          onChange={(e) => {
            const q = Number(e.target.value);
            setQuantity(q);
            setTotal(q * material.price);
          }}
        />
      </Grid>

      {validateStock && material.stock <= 0 && (
        <SoftBox mb={1}>
          <SoftTypography component="label" variant="caption" fontWeight="bold" color="error">
            Este produto não possui estoque disponível. Registre uma entrada no estoque para que
            seja possível registrar uma saída
          </SoftTypography>
        </SoftBox>
      )}
    </Grid>
  );
};
