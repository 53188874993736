import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid, Modal } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEvents } from "hooks/useEvents";
import { useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { DocumentApi } from "services/Document";
import { MaterialApi } from "services/Material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export const DocUploadModal = ({ showing, onClose, onSuccess, project }) => {
  const [loading, setLoading] = useState(false);

  const { dispatch } = useEvents();

  const onSubmit = async (e) => {
    e.preventDefault();

    const name = e.target["techgest--input-docName"].value;
    const file = e.target["techgest--input-docFile"].files[0];

    setLoading(true);
    const response = await DocumentApi.store(name, file, project.id);
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    dispatch("document-uploaded", {});
    onSuccess();
  };

  if (!showing) return null;

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <SoftButton style={{ position: "absolute", right: 10, top: 10 }} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </SoftButton>
        <p id="child-modal-description">
          {project.name} <br /> Novo documento
        </p>

        <Divider />

        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <Grid container mb={2} spacing={1}>
            <Grid item lg={12} md={6} sm={12}>
              <SoftBox mb={1}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-docName"
                id="techgest--input-docName"
                required
              />
            </Grid>
            <Grid item lg={12} md={6} sm={12}>
              <SoftBox mb={1}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Arquivo:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="file"
                name="techgest--input-docFile"
                id="techgest--input-docFile"
                required
              />
            </Grid>
          </Grid>

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Cancelar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Enviar
                {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Modal>
  );
};
