import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Checkbox, Divider, Grid, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import { MaterialApi } from "services/Material";
import { ProjectApi } from "services/Project";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export const StockModal = ({ showing, onClose, onSuccess, material, projects = [] }) => {
  const [withIssue, setWithIssue] = useState(false);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [issueQuantity, setIssueQuantity] = useState(0);
  const [reason, setReason] = useState("");
  const [projectId, setProjectId] = useState(0);
  const [stepId, setStepId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (withIssue && (!projectId || !stepId || !issueQuantity)) {
      error("Por favor, informe um projeto etapa e quantidade válidos para a saída.");
      return;
    }

    if (withIssue && material.stock + quantity < issueQuantity) {
      error("Quantidade inválida. Não terão itens disponíveis no estoque.");
      return;
    }

    setLoading(true);
    const response = await MaterialApi.issueToStock(material.id, quantity, total, reason, {
      stepId,
      projectId,
      issueQuantity,
      withIssue,
    });
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    onSuccess();
  };

  const fetchSteps = async () => {
    const response = await ProjectApi.listStepsFromProject(projectId);

    if (!response.ok) {
      error(response.message);
      return;
    }

    setSteps(response.steps);

    if (response.steps.length) {
      setStepId(response.steps[0].id);
    }
  };

  useEffect(() => {
    setQuantity(1);
    setIssueQuantity(0);
    setProjectId(0);
    setStepId(0);
    setWithIssue(false);

    if (!material) {
      setTotal(0);
    } else {
      setTotal(material.price);
    }
  }, [material, showing]);

  useEffect(() => {
    if (!projects.length) {
      setProjectId(0);
      return;
    }

    setProjectId(projects[0].id);
  }, [projects]);

  useEffect(() => {
    fetchSteps();
  }, [projectId]);

  if (!showing || !material) return null;

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <p id="child-modal-description">Compra - {material.name}</p>

        <Divider />

        {/* Material editing form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <Grid container mb={2} gap={1}>
            <Grid item lg={12} md={6} sm={12}>
              <SoftBox mb={1}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Quantidade ({material.unit}):
                </SoftTypography>
              </SoftBox>
              <SoftInput
                name="techgest--input-quantity"
                id="techgest--input-quantity"
                required
                value={quantity}
                onChange={(e) => {
                  const newQuantity = Number(e.target.value);
                  if (String(e.target.value).length == 0 || Number.isNaN(newQuantity)) {
                    setQuantity(0);
                    setTotal("0");
                    return;
                  }

                  setQuantity(newQuantity);
                  setTotal(material.price * newQuantity);
                }}
              />
            </Grid>
            <Grid item lg={12} md={6} sm={12}>
              <SoftBox mb={1}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Total ({material.price.asMoney()} * {quantity}):
                </SoftTypography>
              </SoftBox>
              <CurrencyInput
                onChangeValue={(e) => {
                  setTotal(e.target.value);
                }}
                defaultValue={total}
                InputElement={
                  <SoftInput type="text" name="techgest--input-total" id="techgest--input-total" />
                }
              />
            </Grid>

            {material.price * quantity != total && false && (
              <Grid item lg={12} md={6} sm={12} style={{ marginTop: 30 }}>
                <SoftTypography variant="caption" color="error">
                  O valor pago não confere com o preco do produto. Considere adicionar um motivo:
                </SoftTypography>
                <SoftInput
                  type="text"
                  name="techgest--input-reason"
                  id="techgest--input-reason"
                  placeholder="Justifique a modificacao do preco"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                />
              </Grid>
            )}
          </Grid>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <label htmlFor="techgest--input-withIssue">
                <Checkbox
                  name="techgest--input-withIssue"
                  id="techgest--input-withIssue"
                  value="1"
                  checked={withIssue}
                  onChange={(e) => {
                    setWithIssue(Boolean(e.target.checked));
                  }}
                />
                <SoftTypography variant="caption">Registrar saída ao salvar</SoftTypography>
              </label>
            </SoftBox>
          </SoftBox>

          {withIssue && (
            <Card>
              <SoftBox p={2}>
                <SoftBox mb={2}>
                  <SoftTypography variation="caption">Dados da saída</SoftTypography>
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Obra:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    type="text"
                    name="techgest--input-projectId"
                    id="techgest--input-projectId"
                    value={projectId}
                    onChange={(e) => {
                      setProjectId(e.target.value);
                    }}
                    required
                  >
                    <MenuItem value={0}>Selecione uma obra</MenuItem>
                    {projects.map((project) => (
                      <MenuItem key={`project-${project.id}`} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Etapa:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    type="text"
                    name="techgest--input-stepId"
                    id="techgest--input-stepId"
                    value={stepId}
                    onChange={(e) => {
                      setStepId(e.target.value);
                    }}
                    required
                  >
                    <MenuItem value={0}>Selecione uma etapa</MenuItem>
                    {steps.map((step) => (
                      <MenuItem key={`step-${step.id}`} value={step.id}>
                        {step.prefix} {step.name}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Quantidade ({material.unit}):
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    name="techgest--input-issueQuantity"
                    id="techgest--input-issueQuantity"
                    required
                    value={issueQuantity}
                    autofocus
                    onChange={(e) => {
                      const newQuantity = Number(e.target.value);
                      if (String(e.target.value).length == 0 || Number.isNaN(newQuantity)) {
                        setIssueQuantity(0);
                        return;
                      }

                      setIssueQuantity(newQuantity);
                    }}
                  />
                </SoftBox>
              </SoftBox>
            </Card>
          )}

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Cancelar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Salvar {withIssue ? " e registrar saída" : " no estoque"}
                {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End material editing form */}
      </SoftBox>
    </Modal>
  );
};
