const STORAGE_FILTERS_KEY = "tg--filters";
const STORAGE_GROUP_KEY = "tg--group";
const STORAGE_PROJECT_KEY = "tg--project";

const defaultFilters = {
  groupId: 0,
  projectId: 0,
};

export const ConfigurationService = {
  filters(newValue = null) {
    if (newValue != null) {
      localStorage.setItem(STORAGE_FILTERS_KEY, JSON.stringify(newValue));
      return;
    }

    const onStorage = localStorage.getItem(STORAGE_FILTERS_KEY);

    if (!onStorage) {
      localStorage.setItem(STORAGE_FILTERS_KEY, JSON.stringify(defaultFilters));
      return defaultFilters;
    }

    return JSON.parse(onStorage);
  },
  group(group) {
    if (typeof group != "undefined") {
      if (group == null) {
        localStorage.removeItem(STORAGE_GROUP_KEY);
        return;
      }

      localStorage.setItem(STORAGE_GROUP_KEY, JSON.stringify(group));
      return;
    }

    const onStorage = localStorage.getItem(STORAGE_GROUP_KEY);

    if (!onStorage) {
      return null;
    }

    return JSON.parse(onStorage);
  },
  project(project) {
    if (typeof project != "undefined") {
      if (project == null) {
        localStorage.removeItem(STORAGE_PROJECT_KEY);
        return;
      }

      localStorage.setItem(STORAGE_PROJECT_KEY, JSON.stringify(project));
      return;
    }

    const onStorage = localStorage.getItem(STORAGE_PROJECT_KEY);

    if (!onStorage) {
      return null;
    }

    return JSON.parse(onStorage);
  },
  clear() {
    ConfigurationService.group(null);
    ConfigurationService.project(null);
    ConfigurationService.filters(defaultFilters);
  },
};
