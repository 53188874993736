import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { IssueCard } from "./issue-card";
import { ProjectApi } from "services/Project";
import { MaterialApi } from "services/Material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export const IssueModal = ({ onSuccess, onClose, onRemove, showing, materials, projects }) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [total, setTotal] = useState({});
  const [projectId, setProjectId] = useState(0);
  const [steps, setSteps] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const stepId = e.target["techgest--input-stepId"].value;
    const materialsPayload = materials.map((m) => ({
      id: m.id,
      quantity: quantity[m.id],
      total: total[m.id],
    }));

    setLoading(true);
    const response = await MaterialApi.issue(projectId, {
      step_id: stepId,
      materials: materialsPayload,
    });
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    onSuccess();
  };

  const fetchSteps = async () => {
    const response = await ProjectApi.listStepsFromProject(projectId);

    if (!response.ok) {
      error(response.message);
      return;
    }

    setSteps(response.steps);
  };

  useEffect(() => {
    fetchSteps();
  }, [projectId]);

  if (!showing || Array.from(materials).length < 1) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <p id="child-modal-description">Registrar Saída</p>

        <Divider />

        {/* Material creation form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Obra:
              </SoftTypography>
            </SoftBox>
            <Select
              type="text"
              name="techgest--input-projectId"
              id="techgest--input-projectId"
              defaultValue={projectId}
              onChange={(e) => {
                setProjectId(e.target.value);
              }}
              required
            >
              <MenuItem value={0}>Selecione uma obra</MenuItem>
              {projects.map((project) => (
                <MenuItem key={`issue-modal-project-${project.id}`} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </SoftBox>

          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Etapa da EAP (opcional):
              </SoftTypography>
            </SoftBox>
            <Select
              type="text"
              name="techgest--input-stepId"
              id="techgest--input-stepId"
              defaultValue={0}
              required
            >
              <MenuItem value={0}>Nenhuma</MenuItem>
              {steps.map((step) => (
                <MenuItem key={`issue-card-step-${step.id}`} value={step.id}>
                  {step.prefix} {step.name}
                </MenuItem>
              ))}
            </Select>
          </SoftBox>

          {materials.map((material) => (
            <IssueCard
              key={`material-issue-card-${material.id}`}
              style={{ marginBottom: 15 }}
              material={material}
              onRemove={onRemove}
              onChangeTotal={(newTotal) => {
                setTotal((old) => {
                  old[material.id] = newTotal;
                  return old;
                });
              }}
              onChangeQuantity={(newQuantity) => {
                if (newQuantity > material.stock) {
                  error(
                    `A quantidade para este material não pode exceder o valor em estoque (${material.stock} ${material.unit})`
                  );
                }

                setQuantity((old) => {
                  old[material.id] = newQuantity;
                  return old;
                });
              }}
            />
          ))}

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Cancelar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Registrar saída {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End material creation form */}
      </SoftBox>
    </Modal>
  );
};
