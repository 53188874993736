import { HTTP_CREATED, HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const TransactionApi = {
  list: async (limit, page, { type, projectId } = { groupId: 0, projectId: 0, type: "" }) => {
    const params = new URLSearchParams();
    params.append("limit", limit);
    params.append("page", page);

    if (!!type) {
      params.append("type", type);
    }

    if (projectId > 0) {
      params.append("project_id", projectId);
    }

    const response = await BaseApi.make("/api/transactions?" + params, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      ...response.data,
    };
  },
  store: async (groupId, projectId, { amount, reason, type }) => {
    const response = await BaseApi.make("/api/transactions", "POST", {
      group_id: groupId,
      project_id: projectId,
      amount,
      reason,
      type,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      ...response.data,
    };
  },
};
