import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { ProjectApi } from "services/Project";
import { Card } from "@mui/material";
import {
  faArrowRight,
  faArrowsH,
  faArrowTrendUp,
  faFileUpload,
  faFolderOpen,
  faMoneyBills,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftButton from "components/SoftButton";
import { useEvents } from "hooks/useEvents";
import colors from "assets/theme/base/colors";
import { IssuesContainer } from "./issues-container";
import { TransactionsContainer } from "./transactions-container";
import { DocUploadModal } from "./doc-upload-modal";
import { DocumentsContainer } from "./documents-container";
import { MovementModal } from "layouts/financies/movement-modal";
import { CurveS } from "layouts/project-eap/curve-s";

function ProjectDetails() {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("v") ?? "transactions";

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [uploading, setUploading] = useState(false);
  const [creatingTransaction, setCreatingTransaction] = useState(false);

  const { subscribe, unsubscribe } = useEvents();

  const fetchProject = async () => {
    const response = await ProjectApi.getBySlug(slug, {
      // without_issues: 1,
      without_steps: 1,
    });

    setLoading(false);

    if (!response.ok) {
      return;
    }

    setData(response);
  };

  const metricsToChart = useMemo(() => {
    if (!data.metrics) {
      return {
        labels: [],
        data: [],
      };
    }

    const labels = data.metrics.dates;
    const datasets = {
      label: "Avancos",
      data: labels.map((label) =>
        data.metrics.progresses[label] ? data.metrics.progresses[label].length : 0
      ),
    };

    return {
      labels,
      datasets,
    };
  }, [data]);

  const metricsToItems = useMemo(() => {
    if (!data.metrics) {
      return {
        labels: [],
        data: [],
      };
    }

    const items = [
      {
        icon: { color: "primary", component: "library_books" },
        label: "etapas finalizadas",
        progress: {
          content: data.metrics.finishedSteps,
          percentage: Math.floor((data.metrics.finishedSteps / data.metrics.totalSteps) * 100),
        },
      },
    ];

    return items;
  }, [data]);

  const costPercentage = useMemo(() => {
    const percentage = {
      text: "",
      color: "info",
    };

    if (!data || !data.project) {
      return percentage;
    }

    if (data.project.realCost <= data.project.estimatedCost) {
      return percentage;
    }

    const percent = parseInt(
      Math.floor((data.project.realCost / data.project.estimatedCost) * 100) - 100
    );

    percentage.text = `+${percent}%`;
    percentage.color = "error";

    return percentage;
  }, [data]);

  const curveBColor = useMemo(() => {
    if (!data || !data.curve) {
      return "error";
    }

    return {
      cost: data.curve.status.cost == "good" ? "success" : "error",
      progress: data.curve.status.progress == "good" ? "success" : "error",
    };
  }, [data]);

  if (!loading && !data.project) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Obra não encontrada</h1>
      </DashboardLayout>
    );
  }

  useEffect(() => {
    fetchProject();

    subscribe("progress-registered", "projectDetails", async () => {
      fetchProject();
    });

    return () => {
      unsubscribe("project-registered", "projectDetails");
    };
  }, []);

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Carregando..</h1>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Obras",
            uri: "/obras",
          },
          {
            name: data.project.name,
            uri: `/obras/${data.project.slug}`,
          },
        ]}
      />

      {data && data.project && (
        <React.Fragment>
          <DocUploadModal
            project={data.project}
            showing={uploading}
            onClose={() => {
              setUploading(false);
            }}
            onSuccess={() => {
              setUploading(false);
            }}
          />

          <MovementModal
            groups={[]}
            projectId={data.project.id}
            showing={creatingTransaction}
            onClose={() => {
              setCreatingTransaction(false);
            }}
            onSuccess={({ keepCreating }) => {
              if (!keepCreating) {
                setCreatingTransaction(false);
              }
            }}
          />
        </React.Fragment>
      )}

      <Grid container>
        <Grid item xl={3} lg={3} sm={12}>
          <SoftTypography>
            <strong>{data.project.name}</strong>
          </SoftTypography>
        </Grid>

        <Grid item xl={9} lg={9} sm={12} container>
          <Grid item xl={3} lg={3} sm={12}></Grid>
          <Grid item xl={3} lg={3} sm={12}></Grid>
          <Grid item xl={6} lg={6} sm={12} container spacing={2}>
            <Grid item xl={4} lg={4} sm={12}></Grid>
            <Grid item xl={4} lg={4} sm={12}>
              <SoftButton
                color="success"
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  setUploading(true);
                }}
              >
                <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: 10 }} />
                Documento
              </SoftButton>
            </Grid>
            <Grid item xl={4} lg={4} sm={12}>
              <SoftButton
                color="success"
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  setCreatingTransaction(true);
                }}
              >
                <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: 10 }} />
                Transacao
              </SoftButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Custo estimado" }}
                count={data.project.estimatedCost.asMoney()}
                icon={{ color: "info", component: "paid" }}
                caption={{
                  color: colors.grey["100"],
                  text: `${data.project.estimatedEapCost.asMoney()} na EAP`,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Custo real" }}
                count={data.project.realCost.asMoney()}
                icon={{
                  color: costPercentage.color,
                  component: "paid",
                }}
                percentage={costPercentage}
                caption={{
                  color: colors.grey["100"],
                  text: `${data.project.realEapCost.asMoney()} na EAP`,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Avanco" }}
                count={`${data.project.progress}%`}
                icon={{ color: "info", component: "public" }}
                caption={{
                  color: colors.grey["100"],
                  text: `${data.metrics.inprogressSteps} etapas em andamento`,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Etapas concluídas" }}
                count={data.metrics.finishedSteps}
                icon={{ color: "info", component: "public" }}
                caption={{
                  color: colors.grey["100"],
                  text: `${data.metrics.finishedSteps}/${data.metrics.totalSteps}`,
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} xl={6} sm={12}>
              <Card>
                <SoftBox p={2}>
                  <CurveS
                    title="Progresso (%)"
                    data={data}
                    curveA={{
                      label: "Estimativa (%)",
                      predicate: ({ finalProgress }) => finalProgress,
                    }}
                    curveB={{
                      label: "Realidade (%)",
                      color: curveBColor.progress,
                      predicate: ({ realProgress }) => realProgress,
                    }}
                  />
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={6} sm={12}>
              <Card>
                <SoftBox p={2}>
                  <CurveS
                    title="Custo (R$)"
                    data={data}
                    curveA={{
                      label: "Estimativa (R$)",
                      predicate: ({ finalCost }) => finalCost,
                    }}
                    curveB={{
                      label: "Realidade (R$)",
                      color: curveBColor.cost,
                      predicate: ({ realCost }) => realCost,
                    }}
                  />
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} xl={12} sm={12}>
              <Card className="h-100" style={{ marginBottom: 10, padding: 10 }}>
                <Grid container justifyContent="space-between">
                  <Grid item md={6} sm={12}>
                    <SoftBox pt={3} px={3}>
                      <SoftTypography variant="h6" fontWeight="medium">
                        EAP
                      </SoftTypography>
                      <SoftBox mt={1} mb={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          {data.metrics.totalSteps} etapas no total. Progresso atual em{" "}
                          {data.project.progress}%
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <SoftBox
                      py={3}
                      px={3}
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                    >
                      <SoftButton
                        href={`/obras/${data.project.slug}/eap`}
                        target={`eap-${data.project.id}`}
                        type="submit"
                        color="info"
                      >
                        Gerenciar EAP
                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faArrowTrendUp} />
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Card>
              <SoftBox mb={2}>
                {false && (
                  <Grid container spacing={1}>
                    <Grid item xl={4} lg={4} sm={12} xs={4}>
                      <SoftButton
                        color={view == "transactions" ? "info" : "white"}
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setSearchParams(() => {
                            const p = new URLSearchParams();
                            p.append("v", "transactions");
                            return p;
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faMoneyBills} style={{ marginRight: 10 }} />
                        <span className="mobile-hidden">Financeiro</span>
                      </SoftButton>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={4}>
                      <SoftButton
                        color={view == "issues" ? "info" : "white"}
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setSearchParams(() => {
                            const p = new URLSearchParams();
                            p.append("v", "issues");
                            return p;
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowsH} style={{ marginRight: 10 }} />
                        <span className="mobile-hidden">Movimentacoes</span>
                      </SoftButton>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={4}>
                      <SoftButton
                        color={view == "docs" ? "info" : "white"}
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={() => {
                          setSearchParams(() => {
                            const p = new URLSearchParams();
                            p.append("v", "docs");
                            return p;
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faFolderOpen} style={{ marginRight: 10 }} />
                        <span className="mobile-hidden">Documentos</span>
                      </SoftButton>
                    </Grid>
                  </Grid>
                )}
              </SoftBox>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <SoftBox>
                {data && data.project && <IssuesContainer projectId={data.project.id} />}
              </SoftBox>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <SoftBox>
                {data && data.project && <TransactionsContainer projectId={data.project.id} />}
              </SoftBox>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <SoftBox>
                {data && data.project && <DocumentsContainer projectId={data.project.id} />}
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectDetails;
