import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import { useEffect, useMemo, useState } from "react";
import { Card } from "@mui/material";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftButton from "components/SoftButton";
import { GroupApi } from "services/Group";
import { MovementModal } from "./movement-modal";
import SoftInput from "components/SoftInput";
import { TransactionApi } from "services/Transaction";
import { Transaction } from "./transaction";
import { useEvents } from "hooks/useEvents";
import { AuthService } from "services/Auth";

const TRANSACTIONS_LIMIT = 3;
const TYPE_TRANSLATIONS = {
  income: "receita",
  outcome: "despesa",
};

function Financies() {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [groups, setGroups] = useState([]);
  const [creating, setCreating] = useState(false);
  const { subscribe, unsubscribe } = useEvents();

  const metricsToChart = useMemo(() => {
    return {
      labels: [],
      datasets: {
        label: "asdas",
        data: [],
      },
    };
  }, []);

  const metricsToItems = useMemo(() => {
    const items = [
      {
        icon: { color: "success", component: "money" },
        label: "Receitas",
        progress: {
          content: 8,
          percentage: 80,
        },
      },
      {
        icon: { color: "error", component: "money" },
        label: "Despesas",
        progress: {
          content: 2,
          percentage: 20,
        },
        reverse: true,
      },
    ];

    return items;
  }, [data]);

  const fetchGroups = async () => {
    const response = await GroupApi.list();

    if (!response.ok) {
      error(response.message);
      return;
    }

    setGroups(response.groups);
  };

  const fetchTransactions = async () => {
    setLoadingTransactions(true);
    const response = await TransactionApi.list(TRANSACTIONS_LIMIT, page);
    setLoadingTransactions(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    setData(response.data);

    if (page == 1) {
      setTransactions(response.data.data);
      return;
    }

    setTransactions([...transactions, ...response.data.data]);
  };

  const filteredTransactions = useMemo(() => {
    if (String(searchTerm).length <= 0) {
      return transactions;
    }

    return transactions.filter((t) => {
      if (t.reason && t.reason.toLowerCase().includes(searchTerm)) {
        return true;
      }

      if (t.project && t.project.name.toLowerCase().includes(searchTerm)) {
        return true;
      }

      if (t.user && t.user.name.toLowerCase().includes(searchTerm)) {
        return true;
      }

      if (TYPE_TRANSLATIONS[t.type] == searchTerm) {
        return true;
      }

      return false;
    });
  }, [transactions, searchTerm]);

  useEffect(() => {
    fetchGroups();

    subscribe("filters-updated", "financies", () => {
      if (page == 1) {
        fetchTransactions();
        return;
      }

      setPage(1);
    });

    return () => {
      unsubscribe("filters-changed", "financies");
    };
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [page]);

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Carregando..</h1>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Financeiro",
            uri: "/financeiro",
          },
        ]}
      />
      <SoftTypography>
        <strong>Financeiro</strong>
      </SoftTypography>

      <MovementModal
        showing={creating}
        groups={groups}
        onClose={() => setCreating(false)}
        onSuccess={() => {
          setPage(1);
          fetchTransactions();
        }}
      />

      <SoftBox py={2}>
        <Grid container gap={1}>
          {/* <Grid item xl={2} lg={2} sm={6}>
            <SoftButton color="info" variant="contained" style={{ width: "100%" }}>
              <FontAwesomeIcon style={{ marginRight: 10 }} icon={faChartBar} />
              Resumo
            </SoftButton>
          </Grid>
          <Grid item xl={2} lg={2} sm={6}>
            <SoftButton color="white" variant="contained" style={{ width: "100%" }}>
              <FontAwesomeIcon style={{ marginRight: 10 }} icon={faList} />
              Movimentacoes
            </SoftButton>
          </Grid>*/}
          <Grid item xl={2} lg={2} sm={12}>
            <SoftButton
              color="success"
              variant="gradient"
              style={{ width: "100%" }}
              onClick={() => setCreating(true)}
              disabled={AuthService.isBlocked()}
            >
              <FontAwesomeIcon style={{ marginRight: 10 }} icon={faPlus} />
              Nova movimentacao
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={2}>
            {false && (
              <Grid item xs={12} lg={5}>
                <ReportsBarChart
                  title="Movimentacoes"
                  chart={metricsToChart}
                  items={metricsToItems}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={12} xl={12}>
              <Card className="h-100">
                <SoftBox pt={3} px={3}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    {loadingTransactions && (
                      <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: 10 }} />
                    )}
                    Últimas movimentacoes
                  </SoftTypography>
                  <SoftTypography variant="caption">Total: {data.total}</SoftTypography>
                </SoftBox>
                <SoftBox pt={3} px={3}>
                  <SoftInput
                    type="text"
                    placeholder="Busque por autor, obra, material ou tipo"
                    onChange={(e) => {
                      setSearchTerm(e.target.value.toLowerCase());
                    }}
                  />
                </SoftBox>
                <SoftBox p={2}>
                  {filteredTransactions.map((transaction) => (
                    <Transaction key={`transaction-${transaction.id}`} transaction={transaction} />
                  ))}
                </SoftBox>
                <SoftBox alignItems="center" justifyContent="center" p={3}>
                  <SoftButton
                    color="info"
                    disabled={loadingTransactions || transactions.length >= data.total}
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                    Carregar mais
                    {loadingTransactions && (
                      <FontAwesomeIcon icon={faPlus} style={{ marginLeft: 10 }} />
                    )}
                  </SoftButton>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Financies;
