import { faPercentage, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, TableBody, TableContainer } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import React, { useState } from "react";
import { AuthService } from "services/Auth";

const HeaderColumn = ({ name }) => {
  return (
    <SoftBox
      component="th"
      width={"auto"}
      pt={1.5}
      pb={1.25}
      pl={3}
      textAlign={"left"}
      fontSize={typography.size.xxs}
      fontWeight={"bold"}
      color="secondary"
      opacity={0.7}
      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
    >
      {name}
    </SoftBox>
  );
};

const Column = ({ children, type = "raw", align = "left" }) => {
  return (
    <SoftBox
      component="td"
      p={1}
      textAlign={align}
      borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
    >
      {type == "raw" && children}
      {type == "text" && (
        <SoftTypography
          fontWeight="regular"
          fontSize={typography.size.sm}
          color="secondary"
          sx={{
            display: "inline-block",
            width: "max-content",
          }}
        >
          {children}
        </SoftTypography>
      )}
    </SoftBox>
  );
};

const Row = ({
  step,
  onEstimatedCostClick,
  onProgressUpdateClick,
  onDeleteClick,
  onForecastCreateClick,
}) => {
  let progressColor = "dark";

  if (step.progress > 0) {
    progressColor = "info";
  }

  if (step.progress >= 100) {
    progressColor = "success";
  }

  return (
    <SoftBox component="tr">
      <Column type="text" align="right">
        {step.prefix}
      </Column>
      <Column type="raw">
        <SoftTypography variant="caption" color="text">
          {step.name} ({step.progress}%)
        </SoftTypography>
        <br />
        <SoftBox width="8rem">
          <SoftProgress
            value={step.progress}
            color={progressColor}
            variant="gradient"
            label={false}
          />
        </SoftBox>
      </Column>
      <Column type="raw">
        {!!step.pretty_estimated_initial_date && !!step.pretty_estimated_final_date ? (
          <SoftTypography variant="caption">
            De <strong>{step.pretty_estimated_initial_date}</strong> a{" "}
            <strong>{step.pretty_estimated_final_date}</strong>
          </SoftTypography>
        ) : (
          <SoftTypography variant="caption" color="error">
            Não definidas
          </SoftTypography>
        )}
      </Column>
      <Column type="text">{step.realCost.asMoney()}</Column>
      <Column type="raw">
        {step.children.length <= 0 ? (
          <React.Fragment>
            <SoftButton
              color="white"
              variant="contained"
              onClick={() => onEstimatedCostClick(step)}
            >
              {step.estimatedCost.asMoney()}
            </SoftButton>
            <SoftButton
              color="success"
              variant="contained"
              onClick={() => onForecastCreateClick(step)}
              disabled={AuthService.isBlocked()}
            >
              <FontAwesomeIcon icon={faPlus} />
            </SoftButton>
          </React.Fragment>
        ) : (
          <SoftTypography variant="caption" fontWeight="bold">
            {step.estimatedCost.asMoney()}
          </SoftTypography>
        )}
      </Column>
      <Column type="raw">
        <SoftButton
          color="info"
          variant="contained"
          onClick={() => onProgressUpdateClick(step)}
          disabled={AuthService.isBlocked()}
        >
          <FontAwesomeIcon icon={faPercentage} />
        </SoftButton>
        <SoftButton
          color="error"
          variant="contained"
          onClick={() => onDeleteClick(step)}
          disabled={AuthService.isBlocked()}
        >
          <FontAwesomeIcon icon={faTrash} />
        </SoftButton>
      </Column>
    </SoftBox>
  );
};

const renderSteps = (
  steps,
  search,
  { onEstimatedCostClick, onProgressUpdateClick, onDeleteClick, onForecastCreateClick }
) => {
  return steps.map((step) => {
    const onSearch =
      !search ||
      search.length <= 0 ||
      `${step.prefix} ${step.name}`.toLowerCase().includes(search.toLowerCase());

    return (
      <React.Fragment key={`step-${step.id}`}>
        {onSearch && (
          <Row
            step={step}
            onEstimatedCostClick={onEstimatedCostClick}
            onForecastCreateClick={onForecastCreateClick}
            onProgressUpdateClick={onProgressUpdateClick}
            onDeleteClick={onDeleteClick}
          />
        )}
        {step.children.length > 0 &&
          renderSteps(step.children, search, {
            onEstimatedCostClick,
            onProgressUpdateClick,
            onDeleteClick,
            onForecastCreateClick,
          })}
      </React.Fragment>
    );
  });
};

export const EapTable = ({
  project,
  steps,
  onEstimatedCostClick,
  onProgressUpdateClick,
  onDeleteClick,
  onForecastCreateClick,
}) => {
  const [search, setSearch] = useState("");

  return (
    <SoftBox p={2}>
      <SoftInput
        placeholder="Buscar por nome"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <SoftBox mb={2}></SoftBox>
      <TableContainer>
        <Table>
          <SoftBox component="thead">
            <HeaderColumn name="#" />
            <HeaderColumn name="Nome" />
            <HeaderColumn name="Datas" />
            <HeaderColumn name="Custo Real" />
            <HeaderColumn name="Custo Estimado" />
            <HeaderColumn name="Acao" />
          </SoftBox>
          <TableBody>
            {renderSteps(steps, search, {
              onEstimatedCostClick,
              onProgressUpdateClick,
              onDeleteClick,
              onForecastCreateClick,
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </SoftBox>
  );
};
