import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Modal } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { StepApi } from "services/Step";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
  minWidth: "40vw",
};

export const DatesModal = ({ step, showing, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [estimatedInitialDate, setEstimatedInitialDate] = useState("");
  const [estimatedFinalDate, setEstimatedFinalDate] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const response = await StepApi.updateDates(step.id, estimatedInitialDate, estimatedFinalDate);
    setLoading(false);

    if (!response.ok) {
      error(response.message);
      return;
    }

    success(response.message);
    onSuccess();
  };

  const updateDatesFromStep = () => {
    if (!step) {
      setEstimatedInitialDate("");
      setEstimatedFinalDate("");
      return;
    }

    setEstimatedInitialDate(step.estimatedInitialDate);
    setEstimatedFinalDate(step.estimatedFinalDate);
  };

  useEffect(() => {
    updateDatesFromStep();
  }, [step]);

  if (!showing || !step) {
    return null;
  }

  return (
    <Modal open={true} onClose={onClose}>
      <SoftBox sx={{ ...style }}>
        <SoftTypography variant="p" fontSize="22">
          Datas - {step.name}
        </SoftTypography>

        <Divider />

        {/* Dates creation form */}
        <SoftBox component="form" role="form" onSubmit={onSubmit}>
          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Data inicial:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="date"
                name="techgest--input-estimatedInitialDate"
                id="techgest--input-estimatedInitialDate"
                required
                onChange={(e) => setEstimatedInitialDate(e.target.value)}
                initialValue={estimatedInitialDate}
              />
            </SoftBox>
          </SoftBox>

          <SoftBox mb={2} display="flex">
            <SoftBox mb={2} ml={1} style={{ flexBasis: "100%" }}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Data final:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="date"
                name="techgest--input-estimatedInitialDate"
                id="techgest--input-estimatedFinalDate"
                required
                initialValue={estimatedFinalDate}
                onChange={(e) => setEstimatedFinalDate(e.target.value)}
              />
            </SoftBox>
          </SoftBox>

          <Divider />

          <SoftBox display="flex">
            <SoftBox mr={2}>
              <SoftButton type="button" color="white" onClick={onClose} mr={2}>
                Fechar
              </SoftButton>
            </SoftBox>

            <SoftBox mr={2}>
              <SoftButton type="submit" color="success" disabled={loading}>
                Registrar {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {/* End dates creation form */}
      </SoftBox>
    </Modal>
  );
};
