import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Soft UI Dashboard React context
import { useSoftUIController, setOpenConfigurator } from "context";
import { MenuItem, Select } from "@mui/material";
import { GroupApi } from "services/Group";
import { ProjectApi } from "services/Project";
import { ConfigurationService } from "services/Configuration";
import { useEvents } from "hooks/useEvents";

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator } = controller;
  const [disabled, setDisabled] = useState(false);
  const [groups, setGroups] = useState([]);
  const [projects, setProjects] = useState([]);
  const [groupId, setGroupId] = useState(ConfigurationService.filters().groupId);
  const [projectId, setProjectId] = useState(ConfigurationService.filters().projectId);

  const { dispatch: dispatchEvent, unsubscribe, subscribe } = useEvents();

  const fetchGroups = async () => {
    const response = await GroupApi.list();

    if (response.ok) {
      setGroups(response.groups);
    }
  };

  const fetchProjects = async () => {
    if (groupId == 0) {
      setProjects([]);
      return;
    }

    const response = await ProjectApi.list(groupId);

    if (response.ok) {
      setProjects(response.projects);
    }
  };

  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener("resize", handleDisabled);

    handleDisabled();
    fetchGroups();

    subscribe("group-created", "configurator", () => {
      fetchGroups();
    });

    subscribe("group-deleted", "configurator", () => {
      fetchGroups();
    });

    subscribe("group-updated", "configurator", () => {
      fetchGroups();
    });

    subscribe("project-created", "configurator", () => {
      fetchProjects();
    });

    subscribe("project-deleted", "configurator", () => {
      fetchProjects();
    });

    subscribe("project-updated", "configurator", () => {
      fetchProjects();
    });

    return () => {
      window.removeEventListener("resize", handleDisabled);
      unsubscribe("group-created", "configurator");
      unsubscribe("group-updated", "configurator");
      unsubscribe("group-deleted", "configurator");
      unsubscribe("project-created", "configurator");
      unsubscribe("project-updated", "configurator");
      unsubscribe("project-deleted", "configurator");
    };
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [groupId]);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Painel de Controle</SoftTypography>
          <SoftTypography variant="body2" color="text">
            Configuracoes de navegacao
          </SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>

      <Divider />

      <SoftBox pt={1.25} pb={3} px={3}>
        <SoftBox component="form">
          <SoftBox mb={2}>
            <SoftBox mb={1}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Empresa:
              </SoftTypography>
            </SoftBox>
            <Select
              name="techgest--input-group"
              id="techgest--input-group"
              required
              value={groupId}
              onChange={(e) => {
                const newFilters = {
                  groupId: Number(e.target.value),
                  projectId: 0,
                };

                setGroupId(newFilters.groupId);
                setProjectId(newFilters.projectId);
                ConfigurationService.filters(newFilters);
                dispatchEvent("filters-updated", newFilters);
                ConfigurationService.project(null);
                ConfigurationService.group(
                  groups.find((group) => group.id == newFilters.groupId) ?? null
                );
              }}
            >
              <MenuItem value="0">Todas</MenuItem>
              {groups.map((group) => (
                <MenuItem key={`group-${group.id}`} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Obra:
              </SoftTypography>
            </SoftBox>
            <Select
              name="techgest--input-project"
              id="techgest--input-project"
              required
              value={projectId}
              onChange={async (e) => {
                const newFilters = {
                  groupId,
                  projectId: Number(e.target.value),
                };

                setProjectId(newFilters.projectId);
                ConfigurationService.filters(newFilters);

                dispatchEvent("filters-updated", newFilters);
                ConfigurationService.project(
                  projects.find((project) => project.id == newFilters.projectId) ?? null
                );
              }}
            >
              <MenuItem value="0">Todas</MenuItem>
              {projects.map((project) => (
                <MenuItem key={`project-${project.id}`} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
