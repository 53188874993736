import { useState, useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "layouts/configurator";
import theme from "assets/theme";
import routes from "routes";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brand from "assets/images/logo-ct.png";
import ProjectDetails from "layouts/project-details";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ProjectEap from "layouts/project-eap";

import "./styles.css";
import EapProgress from "eap-progress";
import { AuthService } from "services/Auth";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import SoftBox from "components/SoftBox";
import { Card } from "@mui/material";
import SoftTypography from "components/SoftTypography";

window.alert = toast;
window.error = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });
window.success = (msg) =>
  toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });

const ErrorFallback = () => {
  return (
    <Card>
      <SoftBox p={10} alignItems="center" justifyContent="center" display="flex" direction="column">
        <SoftBox>
          <SoftTypography variant="h4" color="error">
            Opa!
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography variant="p" color="text">
            Tivemos um erro inesperado. Você pode ver os detalhes no console caso queira reportar a
            um desenvolvedor. Caso contrário, espere e logo estaremos de volta :D
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary fallback={<ErrorFallback />}>
        <ToastContainer />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="TechGest App"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {AuthService.isLogged() && <EapProgress />}
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="/entrar" element={<SignIn />} />
          <Route path="/cadastro" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/obras/:slug" element={<ProjectDetails />} />
          <Route path="/obras/:slug/eap" element={<ProjectEap />} />
          <Route path="*" element={<Navigate to="/entrar" />} />
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
