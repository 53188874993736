import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { useMemo } from "react";

export const CurveS = ({ data, curveA, curveB, title = "Curva S" }) => {
  const metricsToLines = useMemo(() => {
    if (!data || !data.curve) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const datasets = [];
    const labels = data.curve.estimations.map(
      (estimation) => `${estimation.pretty_starts_at} a ${estimation.pretty_ends_at}`
    );

    datasets.push({
      label: curveA.label,
      color: curveA.color ?? "info",
      data: data.curve.estimations.map(curveA.predicate),
    });

    datasets.push({
      label: curveB.label,
      color: curveB.color ?? "warning",
      data: data.curve.estimations.map(curveB.predicate),
    });

    return {
      labels,
      datasets,
    };
  }, [data, curveA, curveB]);

  return <GradientLineChart title={title} chart={metricsToLines} />;
};
